import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { Connect } from 'aws-amplify-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import {  ListGroup, Button, Form, Modal, Spinner, Dropdown, DropdownButton, Row, Col, Accordion, Card, Popover, OverlayTrigger, Badge, Image } from 'react-bootstrap';
import { ReviewCreator } from './ReviewCreator'
import { NavLink, useHistory } from 'react-router-dom';
import { ItemReviewListItem } from './ItemReview'
import { Spacer, GetUserIdByOwner, getCloudinaryUrlFromKey, normalizeItemReview } from './Helpers'

const GetReviewableItemWithReviews = `query GetReviewableItem($id: ID!) {
    getReviewableItem(id: $id) {
      id
      name
      description
      vendor {
          id
          name
          latitude
          longitude
          city
          territory
      }
      reviews(sortDirection: DESC) {
          items {
            id
            rating
            reviewText
            createdAt
            eatenAt
            itemReviewUserId
            uName
            uPhoto {
                key
                width
                height
            }
            photos {
                key
                width
                height
            }
          }
      }
    }
}`;

const UpdateUserWithFavItem = `mutation UpdateUser($id: ID!, $userFavItemId: ID!) {
    updateUser(input: {id: $id, userFavItemId: $userFavItemId}) {
      id
      favItem {
          id
          name
      }
    }
  }
`;

function FavoriteItemButton(props) {

    const handleSetFavoriteButtonClick = useCallback(
        (eventContext) => {
            
            async function updateUserWithFavItem() {
                
                const awsuser = await Auth.currentAuthenticatedUser();
                const userResult = await API.graphql(graphqlOperation(GetUserIdByOwner, { owner: awsuser.username }));
                var queryResultData = userResult.data.userByOwner;
                
                var user = null;
                if(queryResultData.items.length > 0) {
                    user = queryResultData.items[0];
                }

                if(user.id) {

                    const updateUserResult = await API.graphql({
                        query: UpdateUserWithFavItem,
                        variables: {id: user.id, userFavItemId: props.itemId},
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    });
                    console.info(`Finished await of updateUserResult`);
                    console.info(`Updated User ${updateUserResult.data.updateUser}`);
                }
            };
            updateUserWithFavItem();
        },
        [ ], // Tells React to memoize regardless of arguments.
    );

    return (
        <Fragment>
        { props.authState === 'signedIn' && 
            <Button variant="primary" onClick={handleSetFavoriteButtonClick} style={{ float: "right"}}>Set as favorite doughnut</Button>
        }
        </Fragment>
    );
}

function reviewWithVendor(review, reviewableItem) {
              
    normalizeItemReview(review, null, reviewableItem.vendor, reviewableItem);
    return(
    <ListGroup.Item key={review.id} style={{display: "inline-block", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px", marginBottom: "48px"}}>
        <ItemReviewListItem review={review} showItemLink={false} showUserLink={true} showVendorLink={true} showVendorMap={false} showFullReview={false}/>
    </ListGroup.Item>
    );
}

function ReviewableItemReviewListItems(props) {

    if(!props.reviews || !props.reviews.items) {
        return <Fragment></Fragment>;
    }
    return props.reviews.items.map(function(review) {
        return reviewWithVendor(review, props.reviewableItem);
      });
}
function ReviewableItemReviewList(props) {
    const [showAddReview, setShowAddReview] = useState(false);

    const onAddReviewClicked = useCallback(() => {
            setShowAddReview(true);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    const handleAddReviewModalClosed = useCallback(() => {
            setShowAddReview(false);
        },
        [], // Tells React to memoize regardless of arguments.
    );

    if(!props.reviewableItem || !props.reviewableItem.reviews || !props.reviewableItem.reviews.items || props.reviewableItem.reviews.items.length === 0) {
        return <Fragment></Fragment>;
    }
    return (
        <Fragment>
            <h4 style={{display: "inline-block"}}>Reviews</h4>
            { props.authState === 'signedIn' && 
                        <Fragment>
                            <Button onClick={onAddReviewClicked} 
                                style={{display: "inline-block", verticalAlign: "text-bottom", marginLeft: "6px"}}>
                                Add Review</Button>
                            <Spacer height="36px"/>
                            <Modal show={showAddReview} onHide={handleAddReviewModalClosed}>
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body> 
                                    <ReviewCreator itemId={props.reviewableItem.id} item={props.reviewableItem} onReviewCompleted={handleAddReviewModalClosed}/>
                                </Modal.Body>
                            </Modal>
                        </Fragment>
                    }
            <ListGroup style={{maxWidth: "1002px"}}>
                <ReviewableItemReviewListItems reviews={props.reviewableItem.reviews} reviewableItem={props.reviewableItem}></ReviewableItemReviewListItems>
            </ListGroup>
        </Fragment>
    );
}

class ReviewableItem extends React.Component {
    constructor() {
        super();

        this.state = { 
            showAddReview: false
        };
    }

    getReviewCreator() {
        return (
            <Fragment>
                <ReviewCreator itemId={this.props.item.id} item={this.props.item} onReviewCompleted={this.handleAddReviewModalClosed.bind(this)}/>
            </Fragment>
        );
    }

    onAddReviewClicked() {
        this.setState({showAddReview: true});
    }
    handleAddReviewModalClosed() {
        this.setState({showAddReview: false});
    }
    
    getImage() {
        if(!this.props.item.photo) {
            return (<Fragment></Fragment>);
        }
        return (
            <Fragment>
                <Image src={getCloudinaryUrlFromKey(this.props.item.photo.key, 400)} />
            </Fragment>
        );
    }

    render() {
        console.log("Rendering ReviewableItem");
        if(!this.props.item) {
            return <div></div>
        }
        return (
            <Fragment>
                <div style={{ padding: "24px 48px 24px 48px", marginTop: "100px", marginBottom: "100px" }}>
                    <div style={{display: "inline-block"}}>
                        <h1 style={{display: "inline-block"}}>{this.props.item.name}</h1>
                    </div>
                    <div className="headerTextLight">by <NavLink to={`/v/${this.props.item.vendor.id}`}>{this.props.item.vendor.name}</NavLink></div>
                    <p style={{marginTop: "12px"}}>{this.props.item.description}</p>
                    <Authenticator hideDefault="true">
                        <FavoriteItemButton itemId={this.props.item.id} />
                    </Authenticator>
                    <div style={{height: "36px", borderTop: "1px solid #456" }}/>
                    {this.getImage()}
                    <ReviewableItemReviewList 
                        reviewableItem={this.props.item} 
                        handleAddReviewModalClosed={this.handleAddReviewModalClosed.bind(this)}
                        authState={this.props.authState} ></ReviewableItemReviewList>
                </div>
            </Fragment>
        );
    }
}

class ReviewableItemListItem extends React.Component {
    constructor() {
        super();

        this.state = { 
            showAddReview: false
        };
    }

    onAddReviewClicked() {
        this.setState({showAddReview: true});
    }
    handleAddReviewModalClosed() {
        this.setState({showAddReview: false});
    }

    getReviewCreator() {
        return (
            <Fragment>
                <ReviewCreator item={this.props.item} vendor={this.props.vendor} onReviewCompleted={this.handleAddReviewModalClosed.bind(this)}/>
            </Fragment>
        );
    }

    getAddReviewButton() {
        return (
            <Fragment>
            { this.props.authState === 'signedIn' && 
                <Fragment>
                    <Button style={{display: "inline-block", marginLeft: "6px", verticalAlign: "text-bottom"}} onClick={this.onAddReviewClicked.bind(this)}>Add Review</Button>
                    <Modal show={this.state.showAddReview} onHide={this.handleAddReviewModalClosed.bind(this)}>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            {this.getReviewCreator()}
                        </Modal.Body>
                    </Modal>
                </Fragment>
            }
            </Fragment>);
    }

    render() {
        console.log("Rendering ReviewableItemListItem");
        if(!this.props.item) {
            return <div></div>
        }
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "0px", marginBottom: "0px" }}>
                    <h5 style={{display: "inline-block"}}><NavLink to={`/d/${this.props.item.id}`}>{this.props.item.name}</NavLink></h5>
                    {this.getAddReviewButton()}
                    <p>{this.props.item.description}</p>
                </div>
            </Fragment>
        );
    }
}

function ReviewableItemLoader(props) {
    const [reviewableItem, setReviewableItem] = useState(null);

    useEffect(() => {
        async function getReviewableItem() {
            const reviewableItem = await API.graphql({
                query: GetReviewableItemWithReviews,
                variables: {id: props.id}
              });
              setReviewableItem(reviewableItem.data.getReviewableItem);
        }
        getReviewableItem();
    }, []);

    return (
        <Fragment>
            <Authenticator hideDefault="true">
                <ReviewableItem item={reviewableItem}></ReviewableItem>
            </Authenticator>
        </Fragment>
    );
}

export { ReviewableItemLoader, ReviewableItem, ReviewableItemListItem };
