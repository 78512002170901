import React, { Fragment, Component, useState, useCallback } from 'react';
import { Authenticator } from 'aws-amplify-react';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut, AmplifyConfirmSignIn, AmplifyConfirmSignUp, AmplifyGreetings, AmplifyForgotPassword, AmplifyRequireNewPassword, AmplifyTotpSetup, AmplifyVerifyContact } from '@aws-amplify/ui-react';
//import { AuthState } from '@aws-amplify/ui-components'
import { Button, Navbar, Nav, Modal } from 'react-bootstrap';
import { DynamicHomePage } from './SignedOutDynamicHome';
import { NavigationBar } from './NavigationBar';
import { MeLoader } from './User';


function getCloudfrontUrlFromKey(imgKey) {
    if(imgKey) {
        var cdnUrl = "https://dkjb6gkpigiie.cloudfront.net/"+imgKey;
        if(cdnUrl.length > 0) {
            return cdnUrl;
        }
    }
    return null;
}

function HomePageLoader(props) {
    return(
        <Authenticator hideDefault="true">
            <DynamicHomePage></DynamicHomePage>
        </Authenticator>);
}

/*function HomePageSwitcher(props) {
    if(props.authState !== 'signedIn') {
        return (
            <Fragment>
                <SignedOutDynamicHomePage></SignedOutDynamicHomePage>
            </Fragment>
        );
    }
    return (<HomePage></HomePage>);
}

function HomePage(props) {
    return(
        <div>
            <MeLoader></MeLoader>
        </div>
    );
}*/

export { HomePageLoader };
