import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';

import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { ListGroup, Button, Image } from 'react-bootstrap';
import FigureImage from 'react-bootstrap/FigureImage';
import { ItemReviewListItem } from './ItemReview';
import { UserEditor } from './UserCreator';
import { Connect } from 'aws-amplify-react';
import { NavLink, useHistory } from 'react-router-dom';
import { getCloudinaryUrlFromKey, normalizeItemReview } from './Helpers';
import { S3ImageUpload } from './ImageUploader';
import * as mutations from '../graphql/mutations';


const GetUserByOwner = `query GetUserByOwner($owner: String!) {
    userByOwner(owner: $owner, limit: 1) {
    items {
      id
      name
      owner
      joinDate
      photo {
          key
          width
          height
      }
      favVendor {
          id
          name
      }
      favItem {
          id
          name
      }
      itemReviews(sortDirection: DESC) {
        items {
            id
            rating
            reviewText
            createdAt
            eatenAt
            itemReviewReviewableItemId
            riName
            itemReviewVendorId
            vName
            vCity
            vTerritory
            vLatitude
            vLongitude
            photos {
                key
                width
                height
            }
        }
      }
    }
    }
}`;

const GetUser = `query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      owner
      joinDate
      photo {
          key
          width
          height
      }
      favVendor {
          id
          name
      }
      favItem {
          id
          name
      }
      itemReviews(sortDirection: DESC) {
          items {
            id
            rating
            reviewText
            createdAt
            eatenAt
            itemReviewReviewableItemId
            riName
            itemReviewVendorId
            vName
            vCity
            vTerritory
            vLatitude
            vLongitude
            photos {
                key
                width
                height
            }
          }
      }
    }
}`;

function itemReviewWithUser(review, user, editable) {
    normalizeItemReview(review, user, null, null);
    return(
    <ListGroup.Item key={review.id} style={{display: "inline-block", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px", marginBottom: "48px"}}>
        <ItemReviewListItem review={review} editable={editable} showItemLink={true} showUserLink={false} showVendorLink={true} showVendorMap={true} showFullReview={false}/>
    </ListGroup.Item>
    );
}

function UserItemReviewListItems(props) {

    if(!props.user.itemReviews || !props.user.itemReviews.items) {
        return <Fragment></Fragment>;
    }
    return props.user.itemReviews.items.map(function(review) {
        return itemReviewWithUser(review, props.user, props.editable);
    });
}

function ItemReviewWithUserListItems(props) {

    if(!props.itemReviews) {
        return <Fragment></Fragment>;
    }
    return props.itemReviews.map(function(review) {
        return itemReviewWithUser(review.review, review.user, props.editable);
    });
}


function FavItem(props) {
    if(!props.favItem) {
        return <Fragment></Fragment>;
    }
    return (
        <div className="headerTextLight" style={{display: "inline-block", marginRight: "12px"}}>Favorite Doughnut: <NavLink to={`/d/${props.favItem.id}`}>{props.favItem.name}</NavLink></div>
    );
}

function FavShop(props) {
    if(!props.favVendor) {
        return <Fragment></Fragment>;
    }
    return (
        <div className="headerTextLight"  style={{display: "inline-block"}}>Favorite Shop: <NavLink to={`/v/${props.favVendor.id}`}>{props.favVendor.name}</NavLink></div>
    );
}

function JoinDate(props) {
    if(props.joinDate) {
        var date = new Date(props.joinDate);
        return "Joined " + date.getFullYear();
    }
    else {
        return "";
    }
}
function UserEditorWrapper(props) {
    
    // onEditCompleted(userName) {
    //     this.setState({userName: userName});
    // }
    //if(!this.props.editable) {
        return <Fragment></Fragment>;
    //}
    //return (
    //    <UserEditor userId={props.user.id} currentUserName={props.user.name} onEditCompleted={this.onEditCompleted.bind(this)}/>
    //);
    //}
}
function UserHeader(props) {
    const [userName, setUserName] = useState(null);

    return (
        <div style={{ padding: "24px 12px 24px 12px", marginTop: "100px", marginBottom: "0px" }}>        
            <ProfilePhotoLoader photo={props.user.photo} 
                userId={props.user.id} 
                editable={props.editable} 
                size="108"/>
            <div style={{display: "inline-block", marginLeft: "12px"}}>
                <div style={{display: "inline-block"}}>
                    <h1 style={{display: "inline-block"}}>{props.user.name}</h1>
                    <UserEditorWrapper />
                </div>
                <p className="headerTextLight"><JoinDate joinDate={props.user.joinDate} /></p>
            </div>
            <br></br>
            <br></br>
            <div style={{display: (props.showFavs) ? "inline" : "none"}}>
                <FavItem favItem={props.user.favItem} />
                <FavShop favVendor={props.user.favVendor} />
            </div>
        </div>
    );
}

function UserReviewsHeader() {
    return (
        <h1>Reviews</h1>
    );
}

function UserReviews(props) {
    return (
        <ListGroup style={{maxWidth: "1002px"}}>
            <UserItemReviewListItems user={props.user} editable={props.editable}></UserItemReviewListItems>
        </ListGroup>
    );
}

class User extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        console.log("Rendering User");
        return (
            <div style={{}}>
                <UserHeader user={this.props.user} showFavs={true} editable={this.props.editable} />
                <UserReviewsHeader />
                <UserReviews user={this.props.user} editable={this.props.editable} />
            </div>
        );
    }
}

function getMergedReviewList(users) {

    var reviewList = [];
    users.forEach(user => {
        
        var userItemList = user.itemReviews.items.map((itemReview) => {
            var userItemReview = {
                user: user,
                review: itemReview
            }
            return userItemReview;
        });
        reviewList = reviewList.concat(userItemList);
    });
    reviewList.sort(function(a, b){return b.review.createdAt - a.review.createdAt});
    return reviewList;
}

function UserLoader(props) {
    const [user, setUser] = useState(null);

    useEffect(() => {
        async function getUser() {
            const user = await API.graphql({
                query: GetUser,
                variables: {id: props.id}
              });
              if(user.data && user.data.getUser) {
                setUser(user.data.getUser);
              }
        }
        getUser();
    }, []);

    if(!user) {
        return (
            <Fragment>
            </Fragment>
        );
    }
    return (
        <Fragment>
            <User user={user}></User>
        </Fragment>
    );
}

function UserGroupLoader(props) {
    const [user, setUser] = useState(null);
    const [user2, setUser2] = useState(null);

    useEffect(() => {
        async function getUser(id, userKey) {
            const user = await API.graphql({
                query: GetUser,
                variables: {id: id}
              });
              if(user.data && user.data.getUser) {
                  if(userKey === 1) {
                    setUser(user.data.getUser);
                  }
                  else {
                    setUser2(user.data.getUser);
                  }
              }
        }
        getUser(props.id, 1);
        getUser(props.id2, 2);
    }, []);

    if(!user || !user2) {
        return (
            <Fragment>
            </Fragment>
        );
    }
    var users = [user, user2];
    var mergedList = getMergedReviewList(users);
    return (
        <Fragment>
            {/* <div style={{display: "flex"}}> */}
            <div style={{display: "inline-block"}}>
                <UserHeader user={user} showFavs={false} />
            </div>
            {/* <div className="headerTextLight" style={{flexGrow: "1", marginBottom: "-44px", alignItems: "flex-end", display: "inline-block", fontSize: "200px", fontWeight: "100", textAlign: "center"}}>
                +
            </div> */}
            <div style={{display: "inline-block", marginLeft: "24px"}}>
                <UserHeader user={user2} showFavs={false} />
            </div>
            <UserReviewsHeader />
            <ItemReviewWithUserListItems itemReviews={mergedList} />
            {/* </div> */}
        </Fragment>
    );
}

class MeLoader extends React.Component {
    constructor() {
        super();
        
        this.state = {
            user: null,
        }

        this.checkUser();
    }

    async checkUser() {
        const awsuser = await Auth.currentAuthenticatedUser();
        this.setState({ awsuser: awsuser });
        const userResult = await API.graphql({
            query: GetUserByOwner,
            variables: {owner: awsuser.username},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        var queryResultData = userResult.data.userByOwner;
        
        var user = null;
        if(queryResultData.items.length > 0) {
            user = queryResultData.items[0];
        }
        this.setState({ user: user });
    }

    render() {
        console.log("Rendering MeLoader");
        if (!this.state.user) {
            return (
                <Fragment><div></div>
                </Fragment>
            );
        }
        return (
            <User user={this.state.user} editable={true}></User>
        ); 

    }
}


function ProfileLink(props) {
    return (
        <Fragment>
            <NavLink to={`/u/${props.userOwner}`} style={{display: "inline-block", overflow: "hidden", textOverflow: "ellipsis", verticalAlign: "bottom" }}>
                <ProfilePhotoLoader photo={props.profilePhoto} editable={false} size={props.size}></ProfilePhotoLoader>
            </NavLink>
        </Fragment>
    );
  }
  
  
function ProfilePhotoLoader(props) {
    const [photo, setPhoto] = useState(props.photo);

    useEffect(() => {
        setPhoto(props.photo);
    }, [props.photo]);

    // const handleNewPhoto = useCallback(
    //     (newPhotoData) => {
            
    //         var newPhoto = newPhotoData.value.data.onCreatePhoto;
    //         if(!newPhoto.album) {
    //             setPhoto(newPhoto);
    //         }
    //     },
    //     [], // Tells React to memoize regardless of arguments.
    // );

    // useEffect(() => {
    //     async function subscribeToNewPhotos() {
    //         const { username: owner } = await Auth.currentAuthenticatedUser();
    //         var subscription = API.graphql(graphqlOperation(subscriptions.onCreatePhoto, { owner: owner } ));
    //         subscription.subscribe({
    //             next: handleNewPhoto
    //         });
    //     }
    //     if(props.editable) {
    //         subscribeToNewPhotos();
    //     }
    // }, [props.editable, handleNewPhoto]);

    // useEffect(() => {
    //     if(props.editable) {
    //         subscribeToNewPhotos();
    //     }
    // }, [props.editable, handleNewPhoto]);

    const handleSharePhoto = useCallback(
        (newPhotoData) => {
            var photo = {
                key: newPhotoData,
                width: 0,
                height: 0
            }
            async function updateUser(photo) {
                
                var updateUserInput = {
                    id: props.userId,
                    photo: photo,
                }
    
                const updateUserResult = await API.graphql({
                    query: mutations.updateUser,
                    variables: {input: updateUserInput}
                });
                console.info(`Finished await of updateUserResult`);
                console.info(`Updated User ${updateUserResult.data.updateUser}`);   
                
            }
            
            updateUser(photo)
            setPhoto(photo);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    var size = (props.size) ? props.size : "96";
    var sizePx = size + "px";
    return (
        <div style={{width: sizePx, height: sizePx, display: "inline-block", verticalAlign: "text-bottom"}}>
            <ProfilePhoto photo={photo} size={size}>
                <div style={{display: (props.editable) ? "block" : "none", alignSelf: "flex-end", zIndex: "1"}}>
                    <S3ImageUpload userId={props.userId} buttonText="Upload" handleShareImageConfirm={handleSharePhoto} />
                </div>
            </ProfilePhoto>
        </div>
    );
}

class ProfilePhoto extends React.PureComponent {
    
    render() {
        console.log("Rendering ProfilePhoto");
        if(!this.props.photo || !this.props.photo.key) {
            return (
                <div style={{borderRadius: "50%", backgroundColor: "#AEABF2", /*backgroundImage: "radial-gradient(circle, #DBF9EB, #ABF2D1 66%, transparent 66%)", */
                width: (this.props.size) ? this.props.size + "px" : "96px", height: (this.props.size) ? this.props.size + "px" : "96px", 
                display: "flex", justifyContent: "center"}}>
                    {this.props.children}
                </div>
            );
        }
        return (
            <div style={{backgroundImage: `url(${getCloudinaryUrlFromKey(this.props.photo.key, this.props.size*2, this.props.size*2, true)})`, backgroundSize: "contain",
            width: (this.props.size) ? this.props.size + "px" : "96px", height: (this.props.size) ? this.props.size + "px" : "96px", margin: 'auto', borderRadius: "50%",
            display: "flex", justifyContent: "center"}}>
                 {this.props.children}
            </div>
        );
    }
}


export { UserLoader, UserGroupLoader, MeLoader, ProfileLink };
