import React, { Fragment, useState } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {  Button, Form, ListGroup, Modal, Spinner, Dropdown, DropdownButton, Row, Col, Accordion, Card, Popover, OverlayTrigger, Badge, Image } from 'react-bootstrap';
import * as mutations from '../graphql/mutations';
import ReactStars from "react-rating-stars-component";
import { Spacer } from './Helpers'
import { S3ImageUpload } from './ImageUploader'
import { getCloudinaryUrlFromKey, getDaysFromNowEpoch } from './Helpers'
import {ReactComponent as WholeUncookedDonut} from '../WholeUncookedDonut.svg';
import {ReactComponent as WholeDonut} from '../WholeDonut.svg';
import {ReactComponent as HalfIcedDonut} from '../HalfIcedDonut.svg';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const GetUserByOwner = `query GetUserByOwner($owner: String!) {
    userByOwner(owner: $owner, limit: 1) {
    items {
      id
      name
      owner
      joinDate
      photo {
        key
        width
        height
      }
    }
    }
}`;

function ReviewRater(props) {
    const ratingChanged = (newRating) => {
        console.log(newRating*2);
        props.onChange(newRating*2);
    };
    
    return (
        <Fragment>
            <p style={{marginBottom: "0px"}}>Rating</p>
        <ReactStars
        count={5}
        onChange={ratingChanged}
        size={36}
        isHalf={true}
        emptyIcon={<WholeUncookedDonut width="36px" opacity=".75" />}
        halfIcon={<HalfIcedDonut width="36px"/>}
        filledIcon={<WholeDonut width="36px"/>}
        />
        </Fragment>
    );
}

class ReviewCreator extends React.Component {
    constructor() {
        super();  
        
        this.state = { 
            itemReviewDraftId: 0,
            itemReviewId: 0,
            itemReviewText: "",
            itemReviewRating: 0,
            photos: [],
            eatenDate: new Date(),
        };

        this.checkUser();
    }
    async checkUser() {
        const awsuser = await Auth.currentAuthenticatedUser();
        this.setState({ awsuser: awsuser });
        const userResult = await API.graphql(graphqlOperation(GetUserByOwner, { owner: awsuser.username }));
        var queryResultData = userResult.data.userByOwner;
        
        var user = null;
        if(queryResultData.items.length > 0) {
            user = queryResultData.items[0];
        }
        this.setState({ user: user });
        this.createItemReview();
    }

    async createItemReview() {
        
        if(!this.props || (!this.props.vendorId && !this.props.item.vendor.id)) {
            return;
        }
        var vendorId = this.props.vendorId;
        if(!vendorId) {
            vendorId = this.props.item.vendor.id;
        }
        var itemId = this.props.itemId;
        if(!itemId) {
            itemId = this.props.item.id;
        }
        if(this.state.user) {
            
            var createItemReviewDraftInput = {
                rating: this.state.itemReviewRating,
                reviewText: this.state.itemReviewText,
                eatenAt: this.state.eatenDate,
                itemReviewDraftUserId: this.state.user.id,
                itemReviewDraftReviewableItemId: itemId,
                itemReviewDraftVendorId: vendorId,
                ttl: getDaysFromNowEpoch(7)
            }

            const createItemReviewDraftResult = await API.graphql({
                query: mutations.createItemReviewDraft,
                variables: {input: createItemReviewDraftInput},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
              });
            console.info(`Finished await of createItemReviewDraftResult`);
            console.info(`Created Item Review Draft ${createItemReviewDraftResult.data.createItemReviewDraft}`);   
            
            this.setState({itemReviewDraftId: createItemReviewDraftResult.data.createItemReviewDraft.id});
            
        }
    }
    async publishItemReview(event) {
        event.preventDefault();
        var vendorId = this.props.vendorId;
        if(!vendorId) {
            vendorId = this.props.item.vendor.id;
        }
        var itemId = this.props.itemId;
        if(!itemId) {
            itemId = this.props.item.id;
        }
        if(this.state.user && this.state.itemReviewDraftId !== 0) {

            // TODO: Use the draft item to populate this if user left before publishing.
            //const itemReviewDraftResult = await API.graphql(graphqlOperation(mutations.getItemReviewDraft, { id: this.state.itemReviewDraftId }));
            //var itemReviewDraft = itemReviewDraftResult.data.getItemReviewDraft;

            var createItemReviewInput = {
                rating: this.state.itemReviewRating,
                reviewText: this.state.itemReviewText,
                itemReviewUserId: this.state.user.id,
                itemReviewReviewableItemId: itemId,
                itemReviewVendorId: vendorId,
                photos: this.state.photos,
                eatenAt: this.state.eatenDate,
                uName: this.state.user.name,
                uPhoto: this.state.user.photo,
                riName: this.props.item.name,
                vName: this.props.item.vendor.name,
                vCity: this.props.item.vendor.city,
                vTerritory: this.props.item.vendor.territory,
                vLatitude: this.props.item.vendor.latitude,
                vLongitude: this.props.item.vendor.longitude,
            }

            const createItemReviewResult = await API.graphql({
                query: mutations.createItemReview,
                variables: {input: createItemReviewInput},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
              });
            console.info(`Finished await of createItemReviewResult`);
            console.info(`Created Item Review ${createItemReviewResult.data.createItemReview}`);   
            
            this.setState({itemReviewId: createItemReviewResult.data.createItemReview.id});
            this.props.onReviewCompleted();
        }
    }
    async handleCancel(event) {
        event.preventDefault();
        this.props.onReviewCompleted();
    }
    async handleShareImageConfirm(photoKey) {
        
        if(this.state.user) {
            var photo = {
                key: photoKey,
                width: 0,
                height: 0
            }

            var photos = this.state.photos;
            photos.push(photo);
            var updateItemReviewDraftInput = {
                id: this.state.itemReviewDraftId,
                photos: photos,
            }

            const updateItemReviewDraftResult = await API.graphql({
                query: mutations.updateItemReviewDraft,
                variables: {input: updateItemReviewDraftInput}
              });
            console.info(`Finished await of updateItemReviewResult`);
            console.info(`Updated Item Review ${updateItemReviewDraftResult.data.updateItemReviewDraft}`);   
            
            this.setState({itemReviewDraftId: updateItemReviewDraftResult.data.updateItemReviewDraft.id, photos: photos});
        }
    }

    async updateItemReviewText(event) {
           
        event.preventDefault();
        this.setState({ 
            itemReviewText: event.target.value });
    }

    async updateItemReviewRating(newRating) {
           
        this.setState({ 
            itemReviewRating: newRating });
    }
    
    getImageMap(photos) {
        
        return photos.map(photo =>
            <ListGroup.Item key={photo.key} style={{display: "inline-block", border: "none"}}>
                <Image src={getCloudinaryUrlFromKey(photo.key, 400)} />
            </ListGroup.Item>
        );
    }
    getImages() {
        if(!this.state.photos || this.state.photos.length === 0) {
            return (<Fragment></Fragment>);
        }
        return (
            <Fragment>
                <ListGroup>
                    {this.getImageMap(this.state.photos)}
                </ListGroup>
            </Fragment>
        );
    }
    async updateItemEatenDate(date) {
           
        this.setState({ 
            eatenDate: date });
    }

    render() {
        console.log("Rendering ReviewCreator");
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "0px", marginBottom: "0px" }}>
                   <h2>I ate a {this.props.item.name}</h2>
                   <h6>from {this.props.item.vendor.name}</h6>
                   <Form 
                        noValidate
                        onSubmit={e => this.publishItemReview(e)}>
                        <Form.Group>
                            <Spacer height="12px" />
                            <ReviewRater onChange={this.updateItemReviewRating.bind(this)}></ReviewRater>
                            <Spacer height="12px"></Spacer>
                            <div className="headerTextLight">
                                <div style={{display: "inline-block"}}>
                                on 
                                </div>
                                <div style={{display: "inline-block", marginLeft: "12px"}}>
                                    <DatePicker selected={this.state.eatenDate} onChange={this.updateItemEatenDate.bind(this)} />
                                </div>
                            </div>
                            <Spacer height="12px" />
                            <S3ImageUpload reviewId={this.state.itemReviewId} handleShareImageConfirm={this.handleShareImageConfirm.bind(this)}/>
                            <Spacer height="12px" />
                            {/* <label for="review">Review:</label> */}
                            <textarea id="review" placeholder="Add a review..." name="text" rows="10" cols="57" wrap="soft" 
                                value={this.state.itemReviewText} onChange={this.updateItemReviewText.bind(this)}></textarea>
                            <br></br>
                            <div>
                            <Spacer height="24px" />
                            {this.getImages()}
                            <Button variant="primary" type="submit" style={{ float: "right", marginLeft: "6px"}}>
                                Create
                            </Button>
                            <Button variant="secondary" style={{ float: "right"}} onClick={this.handleCancel.bind(this)}>
                                Cancel
                            </Button>
                            </div>
                        </Form.Group>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

export { ReviewCreator };