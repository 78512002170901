import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';

import { Connect } from 'aws-amplify-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { NavLink, useHistory } from 'react-router-dom';
import { ListGroup, Button } from 'react-bootstrap';
import { ItemReviewListItem } from './ItemReview';
import { VendorFeature } from './SignedOutDynamicHome';
import { normalizeItemReview } from './Helpers';

// TODO: [DGNT-1] This list is A) not sorted, B) not guaranteed to be the most recent reviews.

const getItemReviewsByCreatedAt = /* GraphQL */ `
  query GetItemReviewsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getItemReviewsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
        items {
            id
            rating
            reviewText
            createdAt
            eatenAt
            itemReviewUserId
            uName
            uPhoto {
                key
                width
                height
            }
            itemReviewReviewableItemId
            riName
            itemReviewVendorId
            vName
            vCity
            vTerritory
            vLatitude
            vLongitude
            photos {
                key
                width
                height
            }
        }
        nextToken
    }
  }
`;

const ListItemReviews = `query ListItemReviews {
    listItemReviews(limit: 10) {
    items {
        id
        rating
        reviewText
        createdAt
        eatenAt
        itemReviewUserId
        uName
        uPhoto {
            key
            width
            height
        }
        itemReviewReviewableItemId
        riName
        itemReviewVendorId
        vName
        vCity
        vTerritory
        vLatitude
        vLongitude
        photos {
            key
            width
            height
        }
    }
    }
}`;

function ItemReviewListItems(props) {

    if(!props.reviews || !props.reviews.items) {
        return <Fragment></Fragment>;
    }
    return props.reviews.items.map(review => {
        normalizeItemReview(review, null, null, null);
        return(<ListGroup.Item key={review.id} style={{display: "inline-block", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px", marginBottom: "48px"
                        }}>
            <ItemReviewListItem review={review} showItemLink={true} showUserLink={true} showVendorLink={true} showVendorMap={true} showFullReview={false}/>
        </ListGroup.Item>);
    });
}
function ItemReviewList(props) {

    if(!props.reviews || !props.reviews.items) {
        return <Fragment></Fragment>;
    }
    return (
        <Fragment>
            <ListGroup style={{maxWidth: "1002px"}}>
                <ItemReviewListItems reviews={props.reviews}></ItemReviewListItems>
            </ListGroup>
        </Fragment>
    );
}
function ItemReviewListLoader(props) {
    const [itemReviews, setItemReviews] = useState(null);

    useEffect(() => {
        async function getItemReviews() {
            const itemReviews = await API.graphql({
                query: getItemReviewsByCreatedAt,
                variables: {type: "ItemReview", limit: 10, sortDirection: "DESC"}
              });
              if(itemReviews.data && itemReviews.data.getItemReviewsByCreatedAt && itemReviews.data.getItemReviewsByCreatedAt.items) {
                setItemReviews(itemReviews.data.getItemReviewsByCreatedAt);
              }
        }
        getItemReviews();
    }, []);
    let history = useHistory();
    if(!itemReviews) {
        return (<Fragment></Fragment>);
    }
    return (
        <div style={{ }}>
            <div style={{display: "flex", maxWidth: "1002px", alignItems: "center"}} >
                <h1 style={{display: "inline-block", flexGrow: "2"}}>{props.headerText}</h1>
                <VendorFeature></VendorFeature>
            </div>
            <ItemReviewList reviews={itemReviews}></ItemReviewList>
        </div>
    );
}

export { ItemReviewListLoader };
