import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../graphql/queries';
import {  Button, ListGroup, Form, Modal, Spinner, Dropdown, DropdownButton, Row, Col, Accordion, Card, Popover, OverlayTrigger, Badge, Image } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { Spacer, getFullDayFormattedDate, normalizeItemReview } from './Helpers'
import { getCloudinaryUrlFromKey, GeoApifyMap } from './Helpers'
import * as mutations from '../graphql/mutations';
import {ReactComponent as WholeDonut} from '../WholeDonut.svg';
import {ReactComponent as HalfDonut} from '../HalfDonut.svg';
import {ReactComponent as EatenDonut} from '../EatenDonut.svg';
import {ReactComponent as WholeUncookedDonut} from '../WholeUncookedDonut.svg';
import {ReactComponent as DRLogo} from '../DRLogo.svg';
import {ReactComponent as DonutEarth} from '../DonutEarth.svg';
import { ProfileLink } from './User'
import { PhotoAlbumWithLightbox, PhotoAlbumLightbox, PhotoWithAlbumClickHandler } from './Photo'

const GetItemReview = `query GetItemReview($id: ID!) {
    getItemReview(id: $id) {
        id
        rating
        reviewText
        createdAt
        eatenAt
        itemReviewUserId
        uName
        uPhoto {
            key
            width
            height
        }
        itemReviewReviewableItemId
        riName
        vName
        vCity
        vTerritory
        vLatitude
        vLongitude
        itemReviewVendorId
        photos {
            key
            width
            height
        }
    }
}`;

function ParagraphTextView(props) {
    const [modalShowing, setModalShowing] = useState(false);
    const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0);
    
    const showModal = useCallback(
        (e) => {
            var photoClickIndexStr = e.target.dataset.photoindex;
            var photoClickIndex = parseInt(photoClickIndexStr);
            setModalShowing(true);
            setLightboxPhotoIndex(photoClickIndex);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    const hideModal = useCallback(
        () => {
            setModalShowing(false);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    
    var splitParagraphs = props.text.split(/\r\n|\r|\n/gi);
    const paragraphs = splitParagraphs.filter(paragraph => paragraph.length > 0);
    var visibleParagraphs = paragraphs.count;
    if(props.targetCharacters && props.text.length > props.targetCharacters) {
        visibleParagraphs = 0;
        var maxTargetMiss = props.targetCharacters * 0;
        var maxCharacterCount = props.targetCharacters + maxTargetMiss;
        var minCharacterCount = props.targetCharacters - maxTargetMiss;
        var totalCharacters = 0;
        var lastTargetMiss = maxTargetMiss + 1;
        var i = 0
        var cutoffParagraph = false;
        for(; i < paragraphs.length; i++) {
            var paragraphCharacterCount = paragraphs[i].length;
            var totalCharactersThroughLastParagraphEnd = totalCharacters;
            var totalCharactersThroughCurrentParagraphEnd = totalCharacters + paragraphCharacterCount;

            var currentTargetMiss =  Math.abs(props.targetCharacters - totalCharactersThroughCurrentParagraphEnd);
            
            if(currentTargetMiss > lastTargetMiss && lastTargetMiss < maxTargetMiss) {
                // use end of last paragraph because it was acceptable.
                // don't update visible paragraphs and that just happens.
                // this can't be the first paragraph since targetMiss is initialized to more than max;
                break;
            }
            if(totalCharactersThroughCurrentParagraphEnd > maxCharacterCount) {
                // use best candidate for ending.
                // don't update visible paragraphs and that just happens.
                

                if(lastTargetMiss > maxTargetMiss) {
                    // if there is no best candidate cut off this paragraph instead.
                    var cutoffTextParagraphChars = props.targetCharacters - totalCharactersThroughLastParagraphEnd;
                    var newText = paragraphs[i].slice(0, cutoffTextParagraphChars) + "...";
                    paragraphs[i] = newText;
                    visibleParagraphs++;
                    cutoffParagraph = true;
                }
                break;
            }
            lastTargetMiss = currentTargetMiss;
            visibleParagraphs++;
            totalCharacters =  totalCharacters + paragraphCharacterCount;
        }
    }

    const listItems = paragraphs.slice(0, visibleParagraphs).map((paragraph, index) =>
        {
            if((index === (visibleParagraphs-1)) && (visibleParagraphs !== paragraphs.length || cutoffParagraph)) {
                return(
                    <Fragment key={index}>
                        <p style={{width: "auto", fontSize: "18px", marginBottom: "0px", textOverflow: "ellipsis"}}>{paragraph}
                        <NavLink style={{display: "inline-block"}} 
                            to={props.readMoreLink}>{props.readMoreText}</NavLink>
                        </p>
                       </Fragment>);
            }
            if(paragraph.length === 0) {
                return (<Fragment key={index} />);
            }
            var photo = (<Fragment />);
            if(props.photos && props.photos.length > index) {
                photo = (<PhotoWithAlbumClickHandler onClick={showModal} photoAlbumIndex={index} photo={props.photos[index]} width={700} height={700}/>);
            }
            return(
                <Fragment key={index}>
                    <p style={{width: "auto", fontSize: "18px", textOverflow: "ellipsis"}}>{paragraph}</p> 
                    {photo}
                </Fragment>);
        });
    return (
        <div>
            <div style={{}}>{listItems}</div>
            <PhotoAlbumLightbox photos={props.photos} modalShowing={modalShowing} photoIndex={lightboxPhotoIndex} hideModalHandler={hideModal}>
                {props.children}
            </PhotoAlbumLightbox>
        </div>);
}
function VendorLocation(props) {
    return (<div className="headerTextLight" style={{}}>in {props.vendor.city}, {props.vendor.territory}</div>);
}
function VendorLink(props) {
    return (
        <NavLink to={`/v/${props.vendor.id}`}>{props.vendor.name}</NavLink>);
}

function VendorHeader(props) {
    var vendorDisplayName = (
        <VendorLink vendor={props.review.vendor} />);
    if(!props.showVendorLink) {
        vendorDisplayName = (<span>{props.review.vendor.name}</span>);
    }
    return (
        <div style={{display: "inline-block", verticalAlign: "top"}}>
            <div style={{display: "inline-block", verticalAlign: "top"}}>
                <DonutEarth height="48px"></DonutEarth>
            </div>
            <div style={{display: "inline-block", marginLeft: "6px"}}>
                <div className="headerText" style={{display: "inline-block"}}>at {vendorDisplayName}</div>
                <VendorLocation vendor={props.review.vendor} />
            </div>
        </div>
    );
}
function ReviewableItemHeader(props) {
    
    var itemNameDiv = null;
    if(props.showItemLink) {
        itemNameDiv = (
            <div style={{display: "inline-block"}}>
                <NavLink to={`/d/${props.review.reviewableItem.id}`}>{props.review.reviewableItem.name}</NavLink>
            </div>);
    }
    else {
        itemNameDiv = (<div style={{}}>{props.review.reviewableItem.name}</div>);
    }
    return (
    <Fragment>
        <div style={{display: "inline-block", verticalAlign: "top"}}>
            <DRLogo width="48px"></DRLogo>
        </div>
        <div style={{display: "inline-block", marginLeft: "6px" }}>
            
            <div className="headerText" style={{display: "block"}}>
                <div style={{display: "inline-block"}}>
                The
                </div>
                <div style={{display: "inline-block", marginLeft: "4px"}}>
                    {itemNameDiv}
                </div>
            </div>
            <div className="headerTextLight" style={{display: "inline-block"}}>was rated</div>
            <div style={{margin: "-24px 3px -10px 3px", verticalAlign: "middle", display: "inline-block"}}>
                <ReactStars
                    edit={false}
                    count={5}
                    value={props.review.rating / 2}
                    size={18}
                    isHalf={true}
                    emptyIcon={<div />}
                    halfIcon={<HalfDonut width="18px"/>}
                    filledIcon={<WholeDonut width="18px"/>}
                />
            </div>
        </div>
    </Fragment>);
}
function ReviewerItemReviewHeader(props) {
    var userNameDisplay = (
        <NavLink style={{}} to={`/u/${props.review.user.id}`}>{props.review.user.name}</NavLink>);
    if(!props.showUserLink) {
        userNameDisplay= (<span>{props.review.user.name}</span>);
    }
    return (
        <Fragment>
            <ProfileLink userOwner={props.review.user.id} profilePhoto={props.review.user.photo} userName={props.review.user.name} editable={false} size="48"></ProfileLink>
            <div style={{display: "inline-block", marginLeft: "6px"}}>
                <div className="headerText" style={{display: "inline-block"}}>
                    Eaten
                </div>
                <div className="headerText" style={{ display: "inline-block", marginLeft: "3px", marginRight: "3px"}}>
                by
                </div>
                <div className="headerText" style={{display: "inline-block"}}> 
                    {userNameDisplay}
                </div>
                <div className="headerTextLight" style={{}}>
                    {getFullDayFormattedDate("on", props.review.eatenAt)}
                </div>
            </div>
        </Fragment>);
}
function ItemReviewHeader(props) {
    return (
    <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row", borderBottom: "1px solid #456", paddingBottom: "6px"}}>
        <div style={{display: "flex", flexGrow: "0", flexBasis: "300px", minWidth: "300px", paddingLeft: "6px", paddingBottom: "6px", verticalAlign: "middle"}}>
            <ReviewableItemHeader review={props.review} showItemLink={props.showItemLink}/>
        </div>
        <div style={{display: "flex", flexGrow: "1", flexBasis: "400px", minWidth: "400px", maxWidth: "724px", verticalAlign: "top", paddingLeft: "6px", paddingBottom: "6px"}} >
            <ReviewerItemReviewHeader review={props.review} showUserLink={props.showUserLink}/>
        </div>
        <div style={{display: "flex", flexGrow: "0", flexBasis: "300px", paddingLeft: "6px", paddingBottom: "6px", minWidth: "300px"}}>
            <VendorHeader review={props.review} showVendorLink={props.showVendorLink} />
        </div>
    </div>);
}

class ItemReviewListItem extends React.Component {
    constructor() {
        super();
    }
    getImages() {
    }
    async onDeleteReviewClicked(event) {
        event.preventDefault();
        var deleteItemReviewInput = {
            id: this.props.review.id,
        }

        const deleteItemReviewResult = await API.graphql({
            query: mutations.deleteItemReview,
            variables: {input: deleteItemReviewInput},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          //await API.graphql(graphqlOperation(mutations.createVendor, { input: createVendorInput }), 
           //                                             authMode: 'Cognito');
        console.info(`Finished await of deleteItemReviewResult`);
        console.info(`Deleted Item Review ${deleteItemReviewResult.data.deleteItemReview}`);   
        
        //this.setState({vendorId: createVendorResult.data.createVendor.id});

    }
    getDeleteButton() {
        if(!this.props.editable) {
            return (<Fragment></Fragment>);
        }
        return (<Button onClick={this.onDeleteReviewClicked.bind(this)}>Delete Review</Button>);
    }

    getMap() {
        if(!this.props.showVendorMap) {
            return (<Fragment></Fragment>);
        }
        return (
            <GeoApifyMap width={300} height={300} latitude={this.props.review.vendor.latitude} longitude={this.props.review.vendor.longitude} zoom={9} />
        );
    }
    
    render() {
        console.log("Rendering ItemReviewListItem");
        if(!this.props.review) {
            return (<Fragment></Fragment>);
        }
        var itemReviewHeader = (<ItemReviewHeader review={this.props.review} showItemLink={this.props.showItemLink} showUserLink={this.props.showUserLink} showVendorLink={this.props.showVendorLink} />
            );
        var reviewBlog = null;
        if(this.props.showFullReview) {
            reviewBlog = ( <ParagraphTextView text={this.props.review.reviewText} photos={this.props.review.photos} >
                    {itemReviewHeader}
                </ParagraphTextView>);
        }
        else {
            var targetCharacters = (this.props.showVendorMap) ? 400 : 800;
            reviewBlog = (<ParagraphTextView text={this.props.review.reviewText} targetCharacters={targetCharacters} 
                readMoreLink={`/r/${this.props.review.id}`} readMoreText="Read More" >
                    {itemReviewHeader}
                </ParagraphTextView>);
        }
        var imageThumbnail = null;
        if(!this.props.showFullReview) {
            imageThumbnail = (
            <div style={{display: "flex", flexGrow: "0", flexBasis: "300px", verticalAlign: "top", minWidth: "300px"}}>
                <PhotoAlbumWithLightbox photos={this.props.review.photos} showFullAlbum={this.props.showFullReview}>
                    {itemReviewHeader}
                </PhotoAlbumWithLightbox>
            </div>);
        }
        var minTextWidth = (!this.props.showVendorMap || this.props.showFullReview) ? "500px" : "400px";
        return (
            <div style={{borderWidth: "1px" }}>
                {itemReviewHeader}
                <div style={{display: "flex", flexWrap: "wrap", flexDirection: "row"}}>
                    {imageThumbnail}
                    <div style={{ display: "flex", flexGrow: "1", flexBasis: "400px", padding: "12px 12px 12px 12px", 
                        marginTop: "0px", marginBottom: "0px", maxWidth: "724px", minHeight: "300px",
                        minWidth: minTextWidth}}>
                        <div style={{maxWidth: "700px", width: "auto"}}>
                            <div style={{width: "auto"}}>{reviewBlog}</div>
                            {this.getDeleteButton()}
                        </div>
                    </div>
                    <div style={{display: "flex", flexGrow: "0", flexBasis: "300px", verticalAlign: "top"}}>
                        {this.getMap()}
                    </div>
                </div>
            </div>
        );
    }
}

function ItemReviewLoader(props) {
    const [itemReview, setItemReview] = useState(null);

    useEffect(() => {
        async function getItemReview() {
            const itemReview = await API.graphql({
                query: GetItemReview,
                variables: {id: props.id}
              });
              var itemReviewWithSubClasses = itemReview.data.getItemReview;
              normalizeItemReview(itemReviewWithSubClasses);
              setItemReview(itemReviewWithSubClasses);
        }
        getItemReview();
    }, []);
    console.log("Rendering ItemReviewLoader");
    if(!itemReview) {
        return (<Fragment></Fragment>);
    }
    return (
        <Fragment>
            <div class="lightOpaqueBackground" style={{paddingTop: "100px", maxWidth: "1098px", paddingBottom: "400px"}}>
                <ItemReviewListItem review={itemReview} showFullReview={true} showItemLink={true} showVendorLink={true} showUserLink={true}></ItemReviewListItem>
                <div style={{borderBottom: "1px solid #456"}} />
            </div>
        </Fragment>
    );
}

export { ItemReviewLoader, ItemReviewListItem, ItemReviewHeader, VendorLocation, VendorLink};
