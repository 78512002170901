
import React, { Fragment } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { Button, Spinner, Modal, Form, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

class VendorCreator extends React.Component {
    constructor() {
        super();
        
        this.state = { 
            name: "", 
            description: "",
            rating: 0,
            latitude: 0,
            longitude: 0,
            city: "",
            territory: ""
        };
    }

    async createVendor(event) {
        
        event.preventDefault();
        var createVendorInput = {
            name: this.state.name,
            description: this.state.description,
            rating: this.state.rating,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            city: this.state.city,
            territory: this.state.territory
        }

        const createVendorResult = await API.graphql({
            query: mutations.createVendor,
            variables: {input: createVendorInput},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
          //await API.graphql(graphqlOperation(mutations.createVendor, { input: createVendorInput }), 
           //                                             authMode: 'Cognito');
        console.info(`Finished await of createVendorResult`);
        console.info(`Created vendor ${createVendorResult.data.createVendor}`);   
        
        this.setState({vendorId: createVendorResult.data.createVendor.id});
    }

    async updateStateValue(event, stateKey) {
           
        event.preventDefault();
        this.setState({
            [stateKey]: event.target.value });
    }

    render() {
        console.log("Rendering VendorCreator");
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "100px", marginBottom: "100px" }}>
                   <h1>Create Vendor</h1>
                    <Form 
                        noValidate
                        onSubmit={e => this.createVendor(e)}
                        style={{ width: "353px" }} >
                        <Form.Group>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="Shop Name" onChange={e => this.updateStateValue(e, "name")}/>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="Shop Description" onChange={e => this.updateStateValue(e, "description")}/>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="City (ex: Seattle)" onChange={e => this.updateStateValue(e, "city")}/>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="State (ex: Washington)" onChange={e => this.updateStateValue(e, "territory")}/>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="Latitude" onChange={e => this.updateStateValue(e, "latitude")}/>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="Longitude" onChange={e => this.updateStateValue(e, "longitude")}/>
                            <Button variant="primary" type="submit" style={{ float: "right"}}>
                                Create
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

export { VendorCreator };