
import React, { Fragment } from 'react';
import { Image, NavLink } from 'react-bootstrap';
import {ReactComponent as MapMarker} from '../MapMarker.svg';

function Spacer(props) {

    return (
        <div style={{height: props.height}}></div>
    );
}

function getFullDayFormattedDate(label, dateString) {
    if(dateString) {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var date = new Date(dateString);
        return label + " " + date.toLocaleDateString(undefined, options);
    }
    else {
        return "";
    }
}

const GetUserIdByOwner = `query GetUserByOwner($owner: String!) {
    userByOwner(owner: $owner, limit: 1) {
    items {
      id
    }
    }
}`;

function GeoApifyMap(props) {
    var mapMarkerWidth = props.width / 7;
    var mapMarkerHeight = props.height / 6;
    var widthOffset = (props.width - mapMarkerWidth) / 2;
    var heightOffset = (props.height / 2) - mapMarkerHeight;
    var googleLink = "https://maps.google.com/?q="+props.latitude+","+props.longitude;
    return (
        <div>
            <a href={googleLink} target="_blank">
            
            <div style={{position: "absolute", paddingLeft: widthOffset+"px", paddingTop: heightOffset+"px"}}>
                <MapMarker height={mapMarkerHeight+"px"}></MapMarker>
            </div>
            <Image src={getImgKitUrlForMap(props.width, props.height, props.longitude + "," + props.latitude, props.zoom)} />
            </a>
        </div>
    );
}
function getImgKitUrlForMap(width, height, center, zoom) {
    if(!width || !height) {
        return "";
    }

    //var geoApifyString = "https://maps.geoapify.com/v1/staticmap?style=osm-carto";
    var geoApifyString = "?style=osm-carto";
    geoApifyString += "&width=" + width
    geoApifyString += "&height=" + height;
    geoApifyString += "&center=lonlat:" + center;
    geoApifyString += "&zoom=" + zoom;
    //geoApifyString += "&scaleFactor=" + 2;
    geoApifyString += "&marker=lonlat:" + center + ";";
    geoApifyString += "color:%23ff0000;"
    geoApifyString += "size:medium";
    geoApifyString += "&apiKey=259012bc70854a6588f4120b92ae7a1a"

    //return geoApifyString;
    return "https://ik.imagekit.io/pof18emuc45/geoapify/" + geoApifyString;
    //return getCloudinaryUrlFromKey(geoApifyString, width, height, false, true);
}

function addParameter(currentParameterString, newParameterPrefix, newParameterValue) {
    if(!newParameterValue) {
        return currentParameterString;
    }
    if(currentParameterString.length > 0) {
        currentParameterString += ",";
    }
    return currentParameterString + newParameterPrefix + "_" + newParameterValue;
}
function getCloudinaryUrlFromKey(imgKey, width, height, isProfile, isFetch) {
    var pixelRatio = window.devicePixelRatio;
    var transformationParam = "";
    transformationParam = addParameter(transformationParam, "w", width*pixelRatio);
    transformationParam = addParameter(transformationParam, "h", height*pixelRatio);
   
    if(isProfile) {
        transformationParam = addParameter(transformationParam, "c", "thumb");
        transformationParam = addParameter(transformationParam, "g", "face");
        transformationParam = addParameter(transformationParam, "r", "max");
    }
    else {
        transformationParam = addParameter(transformationParam, "c", "fill");
    }
    transformationParam = transformationParam + "/";
    var fetchOrUploadParam = "upload/";
    if(isFetch) {

        fetchOrUploadParam = "fetch/";
    }
    if(imgKey) {
        var cdnUrl = "https://res.cloudinary.com/dvqt2rsla/image/"+fetchOrUploadParam+transformationParam+imgKey;
        if(cdnUrl.length > 0) {
            return cdnUrl;
        }
    }
    return null;
}

function getDaysFromNowEpoch(days) {
    var currentTime = Math.floor(Date.now() / 1000);
    var day = 86400; //seconds in day.
    var expiration = (currentTime + (day * days));
    return expiration;
}
function getCloudfrontUrlFromKey(imgKey) {
    if(imgKey) {
        var cdnUrl = "https://dkjb6gkpigiie.cloudfront.net/"+imgKey;
        if(cdnUrl.length > 0) {
            return cdnUrl;
        }
    }
    return null;
}
function normalizeItemReview(itemReview, user, vendor, reviewableItem) {
    if(user) {
        itemReview.user = user;
    }
    else {
        itemReview.user = {
            id: itemReview.itemReviewUserId,
            name: itemReview.uName,
            photo: itemReview.uPhoto
        };
    }
    if(vendor) {
        itemReview.vendor = vendor;
    }
    else {
        itemReview.vendor = {
            id: itemReview.itemReviewVendorId,
            name: itemReview.vName,
            city: itemReview.vCity,
            territory: itemReview.vTerritory,
            latitude: itemReview.vLatitude,
            longitude: itemReview.vLongitude,
        };
    }
    if(reviewableItem) {
        itemReview.reviewableItem = reviewableItem;
    }
    else {
        itemReview.reviewableItem = {
            id: itemReview.itemReviewReviewableItemId,
            name: itemReview.riName,
        };
    }
}

export { Spacer, getFullDayFormattedDate, GetUserIdByOwner, getCloudinaryUrlFromKey, getCloudfrontUrlFromKey, getDaysFromNowEpoch, GeoApifyMap, normalizeItemReview };