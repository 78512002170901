import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { Component } from 'react';
import {  Button, Form, Modal, Spinner, Dropdown, DropdownButton, Row, Col, Accordion, Card, Popover, OverlayTrigger, Badge, Image } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';
import axios from 'axios';
import * as mutations from '../graphql/mutations';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { getCloudinaryUrlFromKey } from './Helpers'

const {"v4": uuidv4} = require('uuid');

const GenerateUploadSig = `mutation GenerateUploadSig($contentType: String!) {
    generateUploadSig(contentType: $contentType)
}`;

class S3ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            uploading: false, 
            showAddImage: false, 
            loadingLinkPreview: false, 
            previewImageDisplay: "none", 
            //uploadedS3Key: "public/0feaf90f-dde5-4afa-89bb-e8cc74fd3f9b",
            uploadedS3Key: null,
            horizontalAlignPercent: 50, 
            verticalAlignPercent: 25,
            previewWidth: 100,
            previewHeight: 300,
            previewAspect: 66,
            movingFocus: false,
            aspectPreviewEnabled: false
        };
        this.handleShareImageConfirm = this.handleShareImageConfirm.bind(this);
        this.handleShareImageClick = this.handleShareImageClick.bind(this);
        this.onUploadChange = this.onUploadChange.bind(this);
        this.handleClose = this.handleClose.bind(this)
        this.handleCaptionChange = this.handleCaptionChange.bind(this)
        this.handleCreditChange = this.handleCreditChange.bind(this)
        this.uploadButtonId  = uuidv4();
    }

    uploadFile = async (file, contentType) => {
        const fileName = uuidv4();
        var result = null;
        //TODO: albumId here would definitely be something to validate that it actually belongs to the user.
        console.info(`Starting await of generateUploadSig`);

        //const generateUploadSig = await API.graphql(graphqlOperation(GenerateUploadSig, {contentType: contentType} ), {authMode: 'AWS_IAM' });
        const generateUploadSig = await API.graphql({
            query: GenerateUploadSig,
            variables: {contentType: contentType},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        console.info(`Finished await of generateUploadSig`);
        //TODO: Untested
        console.info(`Got generateUploadSig ${generateUploadSig.data}`);
        
        var response = JSON.parse(generateUploadSig.data.generateUploadSig);
        console.info(`Signature is ${response.signature}`);

        var formData = new FormData();
        formData.append("file", file);
        formData.append("api_key", "541415784158454");
        formData.append("public_id", response.publicId);
        //formData.append("version", 1);
        formData.append("timestamp", response.timeStamp);
        formData.append("upload_preset", response.uploadPreset);
        formData.append("signature", response.signature);
        await fetch("https://api.cloudinary.com/v1_1/dvqt2rsla/image/upload", {
            method: "POST",
            body: formData
          });
        console.log('Uploaded file: ', response.publicId);
        return response.publicId;
    }

    onUploadChange = async (e) => {
        this.setState({ uploading: true });
        this.setState({ showAddImage: true });
        this.setState({ errorMsg: null });

        if(e.target.files.length < 1) {
            this.clearModalState();
            return;
        }
        if(e.target.files.length > 1) {
            this.setState({ uploading: false, errorMsg: "Multiple images were selected. Only one image is allowed." });
            return;
        }
        var file = e.target.files[0];
        /*if (file.size > 2097152) {
            
            this.setState({ uploading: false, errorMsg: `File is too big. Please pick a file smaller than ${
                2097152 / 1048576
              } mb.` });
              return;
        }*/
        var contentType = file.type;
        if(file.name.endsWith('avif')) {
            contentType = "image/avif"
        }
        if((contentType !== "image/jpeg") && (contentType !== "image/avif")) {
            this.setState({ uploading: false, errorMsg: `File is not an allowed type. Choose a jpg or avif.` });
              return;
        }
        var fileResult = await this.uploadFile(file, contentType);
        this.setState({ uploading: false, uploadedPhotoKey: fileResult, uploadedS3Key: fileResult });
    }

    async handleShareImageConfirm() {

        this.clearModalState();
        this.props.handleShareImageConfirm(this.state.uploadedS3Key);
    }
    clearModalState() {

        this.setState({ showAddImage: false, websiteComment: null, websiteLink: null, uploadedPhotoKey: null, uploadedS3Key: null, textCaption: null, uploading: false, errorMsg: null });
    }

    handleClose() {
        this.clearModalState();
    }

    handleShareImageClick() {
        document.getElementById(this.uploadButtonId).click();
        
        //this.setState({ uploading: true });
        this.setState({ showAddImage: true });
    }
    handleCaptionChange(event) {
        let value = event.target.value;
        this.setState({textCaption: value});
    }
    handleCreditChange(event) {
        let value = event.target.value;
        this.setState({creditText: value});
    }

    renderSpinner() {
        if(this.state.loadingLinkPreview) {
            return (<Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{marginRight: "12px", verticalAlign: "baseline"}}/>);
        }
        return (<div></div>);
    }

    onLoadPickerImage(url) {
        this.setState({previewImageDisplay: "visible"});
    }

    render() {
        console.log("Rendering ImageUploader");
        var uploadText = (this.props.buttonText) ? this.props.buttonText : "Uploading";
        var buttonText = (this.props.buttonText) ? this.props.buttonText : "Share Image";
        return (
            <div style={{display: "inline-block"}}>
                <Button
                    disabled={this.state.uploading}
                    icon='file image outline'
                    onClick={this.handleShareImageClick}
                    variant={(this.props.variant) ? this.props.variant : "primary"}
                    >
                {this.state.uploading ? uploadText : buttonText}
                </Button>
                <input
                    id={this.uploadButtonId}
                    type="file"
                    accept='image/*'
                    multiple
                    onChange={this.onUploadChange}
                    style={{ display: 'none' }}
                />
                <Modal show={this.state.showAddImage} onHide={this.handleClose.bind(this)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Share Image</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{textAlign: "center", display: (!this.state.uploading && !this.state.uploadedPhotoKey) ? "block" : "none"}}>
                            <Button
                                icon='file image outline'
                                onClick={this.handleShareImageClick}
                                variant={(this.props.variant) ? this.props.variant : "primary"}
                            >
                                {buttonText}
                            </Button>
                            <p style={{color: "red"}}>{(this.state.errorMsg) ? this.state.errorMsg : ""}</p>
                        </div>
                        <div style={{textAlign: "center", display: (this.state.uploading) ? "block" : "none"}}>
                            <Spinner animation="border" variant="info" style={{margin: 'auto'}}></Spinner>
                            <p>Uploading</p>
                        </div>
                        {/*<div>
                        <S3Image  id='add-image-file-input' picker hidden onLoad={this.onLoadPickerImage.bind(this)} theme={{ photoImg: { margin: "auto", width: "100%", display: this.state.previewImageDisplay }}} />
                        </div> width={this.state.previewWidth} height={this.state.previewHeight}  */}
                        <HookThumbnailViewer photoKey={this.state.uploadedS3Key} aspectRatio={this.state.previewAspect}
                                                horizontalAlignPercent={this.state.horizontalAlignPercent} verticalAlignPercent={this.state.verticalAlignPercent} 
                                                aspectPreviewEnabled={this.state.aspectPreviewEnabled}
                                                movingFocus={this.state.movingFocus}
                                                timeout={500}/>
                        <CaptionCreator handleCaptionChange={this.handleCaptionChange} handleCreditChange={this.handleCreditChange}></CaptionCreator>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="success" disabled={this.state.uploading || this.state.loadingLinkPreview}
                            onClick={this.handleShareImageConfirm}>
                            {this.renderSpinner()}
                        Share Image
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

class CaptionCreator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            caption: props.caption,
            credit: props.credit
        }
    }

    handleCaptionChange(event) {
        let value = event.target.value;
        this.setState({caption: value});
        this.props.handleCaptionChange(event);
    }
    handleCreditChange(event) {
        let value = event.target.value;
        this.setState({credit: value});
        this.props.handleCreditChange(event);
    }

    render() {
        console.log("Rendering CaptionCreator");
        return (
            <div>
            <Form 
                noValidate>
                <Form.Group controlId="formHyperlinkName">
                <Form.Label style={{marginBottom: "6px"}}>Caption</Form.Label>
                <Form.Control type="text" placeholder={this.state.caption} value={this.state.caption} onChange={e => this.handleCaptionChange(e)}/>
                <Form.Label style={{marginBottom: "6px"}}>Credit</Form.Label>
                <Form.Control type="text" placeholder={this.state.credit} value={this.state.credit} onChange={e => this.handleCreditChange(e)}/>
                </Form.Group>
            </Form>
            </div>
        );
    }
}

function getCloudfrontUrlFromKey(imgKey) {
    if(imgKey) {
        var cdnUrl = "https://dkjb6gkpigiie.cloudfront.net/"+imgKey;
        if(cdnUrl.length > 0) {
            return cdnUrl;
        }
    }
    return null;
}

//https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}

function HookThumbnailViewer(props) {
    const [photoKey, setPhotoKey] = useState(null);
    const [attemptCount, setAttemptCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [backgroundUrl, setBackgroundUrl] = useState(null);
    
    useInterval(() => {
        // Your custom logic here
        setPhotoKey(props.photoKey);
        if((attemptCount % 2) === 0) { //TODO: I don't remember why I had to do this at all. COMMENTS, STEPHEN!
            setPhotoKey(null);
        }
        setAttemptCount(attemptCount + 1);
    }, (isLoading && props.photoKey && (attemptCount < 20)) ? 2000 : null);
  
    /*useEffect(() => {
        delay(props.timeout).then(() => {
            const fetchData = async () => {
                setPhotoKey(props.photoKey);
            };
            fetchData();
          });
    }, []);*/

    const onLoadedImageFromUrl = useCallback(
        () => {
            console.log("Cacheable photo loaded from image url");
            setIsLoading(false);
            setBackgroundUrl(getCloudinaryUrlFromKey(photoKey));
        },
        [photoKey], // Tells React to memoize regardless of arguments.
    );

    function getS3Image() {
        if(!photoKey) {
            return (
                <div style={{display: 'none'}}>
                </div>
            );
        } 

        var aspect = 1;
        var fullHeightPixels = 300;
        var fullWidthPixels = 466;
        var widthPercent = 100;
        var heightPixels = fullHeightPixels;

        if(props.aspectRatio < 50) {
            aspect = .25 + (props.aspectRatio/50 *.75);
            var widthPixels = heightPixels * aspect;
            widthPercent = widthPixels / fullWidthPixels * 100;
        }
        else if(props.aspectRatio >= 50) {
            aspect = 1+((props.aspectRatio-50)/50*3);
            var widthPixels = fullHeightPixels * aspect;
            if(widthPixels >= fullWidthPixels) {
                widthPixels = fullWidthPixels;
                heightPixels = fullWidthPixels/aspect;
            }
            widthPercent = widthPixels / fullWidthPixels * 100;
        }

        var widthPercentString = widthPercent + "%";
        var heightString = heightPixels + "px";
        var horizontalAlignPercent = (props.horizontalAlignPercent) ? props.horizontalAlignPercent : "50";
        var verticalAlignPercent = (props.verticalAlignPercent) ? props.verticalAlignPercent : "50";
        var horizontalString = horizontalAlignPercent + "%";
        var verticalString = verticalAlignPercent + "%";

        var dotRadius = 10;
        var datSizeString = (dotRadius * 2) + "px";
        var focusX = (widthPixels * horizontalAlignPercent / 100) - dotRadius;
        var focusY = (heightPixels * verticalAlignPercent / 100) - dotRadius;
        if(backgroundUrl && props.aspectPreviewEnabled) {
            return (
                <div style={{margin: "auto", background: `url(${backgroundUrl})`, backgroundSize: "cover", backgroundPositionX: horizontalString, backgroundPositionY: verticalString, 
                    backgroundRepeat: "no-repeat", width: widthPercentString, height: heightString}}>
                        <div style={{position: "relative", left: focusX, top: focusY}}>
                        <span class="dot" style={{display: (props.movingFocus) ? "block" : "none", width: datSizeString, height: datSizeString, background: "#00ffff94", borderRadius: "50%" }}></span>
                        </div>
                </div>
            );            
        }
        else if(backgroundUrl) {
            return (
                <Image src={backgroundUrl} style={{width: "100%"}} />
            );     
        }
        return (
            <Image src={getCloudinaryUrlFromKey(photoKey)} onLoad={onLoadedImageFromUrl} style={{ margin: 'auto', width: '100%', fontSize: attemptCount }} />
        );
    };

    return (
        <Fragment>
            <div style={{margin: 'auto', width: '100%', textAlign: "center"}}>
                <div style={{display: (isLoading && props.photoKey) ? "inline-block" : "none"}}>
                    <Spinner animation="border" variant="info" style={{margin: 'auto'}}></Spinner>
                    <p>Preparing image</p>
                </div>
                {getS3Image()}
            </div>
        </Fragment>
    );
}


export { S3ImageUpload };