import React, { Fragment, Component, useState, useCallback, useEffect } from 'react';
import { Button, Navbar, Nav, Modal, Form } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { ItemReviewListLoader } from './ReviewList';
import { Spacer } from './Helpers'

function getCloudfrontUrlFromKey(imgKey) {
    if(imgKey) {
        var cdnUrl = "https://dkjb6gkpigiie.cloudfront.net/"+imgKey;
        if(cdnUrl.length > 0) {
            return cdnUrl;
        }
    }
    return null;
}

function VendorFeature(props) {

    const onSubmit = useCallback(
        () => {
            //setShowSignIn(true);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    const onVendorNameSearchChange = useCallback(
        () => {
            //setShowSignIn(true);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    let history = useHistory();
    return(
        <Button  className="headerTextLight" variant="outline-dark" style={{display: "inline-block"}}
            onClick={() => history.push('/shops')}>
            Find Shops
        </Button>
    );
}

function AboutFeature(props) {

    return(
        <div  class="opaqueBackground" style={{maxWidth: "396px", paddingLeft: "48px", paddingRight: "48px", paddingBottom: "48px" }}>
            
            <Spacer height="100px"></Spacer>
            <div style={{border: "1px solid #456"}}>
                <div class="lightOpaqueBackground" style={{padding: "24px"}}>
                    <div class="bg-nav headerTextLight" style={{borderBottom: "1px solid #456"}}>About the Doughnut Review</div>
                    <div>
                        <p>
                            A home for doughnut nuts, and maybe a fun vacation rental for ice cream screamers. Wherever great doughnuts are, we'll be there eventually because: Doughnuts Get Moldy in Darkness.
                        </p>
                        <p>
                            This is a blog by Marisa and Stephen: two Seattle-based doughnut lovers. We may not be able to dine inside due to covid but at least we can still pick up doughnuts.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DynamicHomePage(props) {
  
    return(
        <div>
            <Spacer height="100px"></Spacer>
            <ItemReviewListLoader headerText={"Recent Reviews"}></ItemReviewListLoader>
        </div>
    );
}

export { DynamicHomePage, AboutFeature, VendorFeature };
