import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';

import { Connect } from 'aws-amplify-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { ListGroup, Button, Spinner, Modal, Form, Dropdown, DropdownButton, ButtonGroup, Image } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { ReviewableItemListItem } from './ReviewableItem'
import { ItemReviewListItem } from './ItemReview'
import { Spacer, GetUserIdByOwner, normalizeItemReview } from './Helpers'
import { getCloudinaryUrlFromKey, GeoApifyMap } from './Helpers'
import { ReviewCreator } from './ReviewCreator'


const GetVendor = `query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      name
      description
      latitude
      longitude
      city
      territory
      items {
          items {
              id
              name
              description
          }
      }
      allReviews(sortDirection: DESC) {
          items {
            id
            rating
            reviewText
            createdAt
            eatenAt
            itemReviewUserId
            uName
            uPhoto {
                key
                width
                height
            }
            itemReviewReviewableItemId
            riName
            photos {
                key
                width
                height
            }
          }
      }
    }
}`;

const UpdateUserWithFavVendor = `mutation UpdateUser($id: ID!, $userFavVendorId: ID!) {
    updateUser(input: {id: $id, userFavVendorId: $userFavVendorId}) {
      id
      favVendor {
          id
          name
      }
    }
  }
`;

function reviewableItemWithVendor(reviewableItem, vendor, authState) {
    reviewableItem.vendor = vendor;
    return(
    <ListGroup.Item key={reviewableItem.id} style={{border: "none", minWidth: "300px", maxWidth: "300px", marginRight: "12px"}}>
        <ReviewableItemListItem item={reviewableItem} linkVendor={false} authState={authState} />
    </ListGroup.Item>
    );
}

function VendorReviewableItemListItems(props) {

    if(!props.items || !props.items.items) {
        return <Fragment></Fragment>;
    }
    return props.items.items.map(function(reviewableItem) {
        return reviewableItemWithVendor(reviewableItem, props.vendor, props.authState);
      });
}

function VendorItemList(props) {

    let history = useHistory();
    if(!props.items || !props.items.items) {
        return <Fragment></Fragment>;
    }
    return (
        <Fragment>
            <h4>Menu</h4>
            <ListGroup horizontal style={{maxWidth: "1000px", flexWrap: "wrap"}}>
                <VendorReviewableItemListItems items={props.items} vendor={props.vendor} authState={props.authState}></VendorReviewableItemListItems>
            </ListGroup>
            { props.authState === 'signedIn' && 
                <Button style={{marginTop: "12px"}} onClick={() => history.push('/addDoughnut/'+props.vendor.id)}>Add Doughnut</Button>
            }
        </Fragment>
    );
}

function VendorReviewListItems(props) {

    if(!props.reviews || !props.reviews.items) {
        return <Fragment></Fragment>;
    }
    return props.reviews.items.map(review => {
        normalizeItemReview(review, null, review.vendor, null);
        return (<ListGroup.Item key={review.id} style={{display: "inline-block", paddingLeft: "0px", paddingRight: "0px", paddingBottom: "0px", marginBottom: "48px"}}>
            <ItemReviewListItem review={review} showItemLink={true} showUserLink={true} showVendorLink={false} showVendorMap={false} showFullReview={false}/>
        </ListGroup.Item>);
    });
}
function VendorReviewList(props) {

    if(!props.reviews || !props.reviews.items || props.reviews.items.length === 0) {
        return <Fragment></Fragment>;
    }
    return (
        <Fragment>
            <h4>Reviews</h4>
            <ListGroup style={{maxWidth: "1002px"}}>
                <VendorReviewListItems reviews={props.reviews}></VendorReviewListItems>
            </ListGroup>
        </Fragment>
    );
}

function FavoriteVendorButton(props) {

    const handleSetFavoriteButtonClick = useCallback(
        (eventContext) => {
            
            async function updateUserWithFavVendor() {
                
                const awsuser = await Auth.currentAuthenticatedUser();
                const userResult = await API.graphql(graphqlOperation(GetUserIdByOwner, { owner: awsuser.username }));
                var queryResultData = userResult.data.userByOwner;
                
                var user = null;
                if(queryResultData.items.length > 0) {
                    user = queryResultData.items[0];
                }

                if(user.id) {
                    var updateUserInput = {
                        id: user.id,
                        userFavVendorId: props.vendorId
                    }

                    const updateUserResult = await API.graphql({
                        query: UpdateUserWithFavVendor,
                        variables: {id: user.id, userFavVendorId: props.vendorId},
                        authMode: 'AMAZON_COGNITO_USER_POOLS'
                    });
                    console.info(`Finished await of updateUserResult`);
                    console.info(`Updated User ${updateUserResult.data.updateUser}`);
                }
            };
            updateUserWithFavVendor();
        },
        [ ], // Tells React to memoize regardless of arguments.
    );

    return (
        <Fragment>
        { props.authState === 'signedIn' && 
            <Button variant="primary" onClick={handleSetFavoriteButtonClick} style={{ float: "right"}}>Set as favorite shop</Button>
        }
        </Fragment>
    );
}

function AddReviewButton(props) { 
    const [showAddReview, setShowAddReview] = useState(false);

    const onAddReviewClicked = useCallback(() => {
            setShowAddReview(true);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    const handleAddReviewModalClosed = useCallback(() => {
            setShowAddReview(false);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    if(!props.vendor && 
        (!props.reviewableItem || !props.reviewableItem.reviews || !props.reviewableItem.reviews.items || props.reviewableItem.reviews.items.length === 0)) {
        return <Fragment></Fragment>;
    }
    return (
        <Fragment>
        { props.authState === 'signedIn' && 
            <Fragment>
                <Button onClick={onAddReviewClicked} 
                                style={{display: "inline-block", verticalAlign: "text-bottom", marginLeft: "6px",
                                marginTop: "6px"}}>
                                Add Review</Button>
                                <Spacer height="36px"/>
                <Modal show={showAddReview} onHide={handleAddReviewModalClosed}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body> 
                        <ReviewCreator vendorId={props.vendor.id} onReviewCompleted={handleAddReviewModalClosed}/>
                    </Modal.Body>
                </Modal>
            </Fragment>
        }
        </Fragment>);
}

function VendorMap(props) {
    return (
        <GeoApifyMap width={300} height={300} latitude={props.vendor.latitude} longitude={props.vendor.longitude} zoom={9} />
    );
}

function Vendor(props) {


    return (
        <Fragment>
            <div style={{ padding: "24px 48px 24px 48px", marginTop: "100px", marginBottom: "100px" }}>
                <Authenticator hideDefault="true">
                    <div style={{display: "flex", alignItems: "flex-end"}}>
                        <div style={{display: "inline-block", flexGrow: "1"}}>
                            <h1 style={{display: "inline-block"}}>{props.vendor.name}</h1>
                            <AddReviewButton vendor={props.vendor}></AddReviewButton>
                            <div className="headerTextLight">{props.vendor.description}</div>
                        </div>
                        <div style={{flexGrow: "0"}}>
                            <VendorMap vendor={props.vendor}></VendorMap>
                        </div>
                    </div>
                    <FavoriteVendorButton vendorId={props.vendor.id} />
                    <div style={{height: "24px", borderTop: "1px solid #456" }}/>
                    <VendorItemList items={props.vendor.items} vendor={props.vendor}></VendorItemList>
                    <Spacer height="24px" />
                    <VendorReviewList reviews={props.vendor.allReviews} vendor={props.vendor}></VendorReviewList>
                </Authenticator>
            </div>
        </Fragment>
    );
}


function VendorLoader(props) {
    const [vendor, setVendor] = useState(null);

    useEffect(() => {
        async function getVendor() {
            const vendor = await API.graphql({
                query: GetVendor,
                variables: {id: props.id}
              });
              if(vendor.data && vendor.data.getVendor) {
                setVendor(vendor.data.getVendor);
              }
        }
        getVendor();
    }, []);

    if(!vendor) {
        return (
            <Fragment>
            </Fragment>
        );
    }
    vendor.allReviews.items.forEach(element => {
        element.vendor = vendor;
    });
    return (
        <Fragment>
            <Vendor vendor={vendor}></Vendor>
        </Fragment>
    );
}

class VendorSearch extends React.Component {
    constructor() {
        super();

        this.state = { 
            storeName: "", 
            city: "", 
            territory: "", 
            country: "", 
        };
    }

    async searchForVendor(event) {
        
        //TODO: Search
        /*event.preventDefault();
        var createReviewableItemInput = {
            name: this.state.reviewableItemName,
            description: this.state.reviewableItemDescription,
        }

        const createReviewableItemResult = await API.graphql(graphqlOperation(queries., { input: createReviewableItemInput }));
        console.info(`Finished await of createReviewableItemResult`);
        console.info(`Created ReviewableItem ${createReviewableItemResult.data.createReviewableItem}`);   
        
        this.setState({reviewableItemId: createReviewableItemResult.data.createReviewableItem.id});*/
    }

    async updateStoreName(event) {
           
        event.preventDefault();
        this.setState({ 
            storeName: event.target.value });
    }

    render() {
        console.log("Rendering VendorSearch");
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "100px", marginBottom: "100px" }}>
                   <h1>Find a Doughnut Provider</h1>
                   <Form 
                        noValidate
                        onSubmit={e => this.searchForVendor(e)}>
                        <Form.Group>
                            <Form.Control style={{ width: "353px", float: "left" }} type="text" placeholder="Store Name" onChange={e => this.updateStoreName(e)}/>
                            <Button variant="primary" type="submit" style={{ float: "right"}}>
                                Find
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Fragment>
        );
    }
}


export { VendorLoader };
