/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      name
      photo {
        key
        width
        height
      }
      joinDate
      createdAt
      updatedAt
      favVendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      favItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      itemReviews {
        nextToken
      }
    }
  }
`;
export const createMultiLocationVendor = /* GraphQL */ `
  mutation CreateMultiLocationVendor(
    $input: CreateMultiLocationVendorInput!
    $condition: ModelMultiLocationVendorConditionInput
  ) {
    createMultiLocationVendor(input: $input, condition: $condition) {
      id
      name
      description
      aggregateItemReview
      createdAt
      updatedAt
      locations {
        nextToken
      }
      items {
        nextToken
      }
    }
  }
`;
export const updateMultiLocationVendor = /* GraphQL */ `
  mutation UpdateMultiLocationVendor(
    $input: UpdateMultiLocationVendorInput!
    $condition: ModelMultiLocationVendorConditionInput
  ) {
    updateMultiLocationVendor(input: $input, condition: $condition) {
      id
      name
      description
      aggregateItemReview
      createdAt
      updatedAt
      locations {
        nextToken
      }
      items {
        nextToken
      }
    }
  }
`;
export const deleteMultiLocationVendor = /* GraphQL */ `
  mutation DeleteMultiLocationVendor(
    $input: DeleteMultiLocationVendorInput!
    $condition: ModelMultiLocationVendorConditionInput
  ) {
    deleteMultiLocationVendor(input: $input, condition: $condition) {
      id
      name
      description
      aggregateItemReview
      createdAt
      updatedAt
      locations {
        nextToken
      }
      items {
        nextToken
      }
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      name
      description
      rating
      latitude
      longitude
      streetAddress
      city
      territory
      country
      websiteUrl
      photo {
        key
        width
        height
      }
      aggregateItemReview
      createdAt
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      items {
        nextToken
      }
      allReviews {
        nextToken
      }
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      name
      description
      rating
      latitude
      longitude
      streetAddress
      city
      territory
      country
      websiteUrl
      photo {
        key
        width
        height
      }
      aggregateItemReview
      createdAt
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      items {
        nextToken
      }
      allReviews {
        nextToken
      }
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      name
      description
      rating
      latitude
      longitude
      streetAddress
      city
      territory
      country
      websiteUrl
      photo {
        key
        width
        height
      }
      aggregateItemReview
      createdAt
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      items {
        nextToken
      }
      allReviews {
        nextToken
      }
    }
  }
`;
export const createReviewableItem = /* GraphQL */ `
  mutation CreateReviewableItem(
    $input: CreateReviewableItemInput!
    $condition: ModelReviewableItemConditionInput
  ) {
    createReviewableItem(input: $input, condition: $condition) {
      id
      createdAt
      name
      description
      aggregateReview
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviews {
        nextToken
      }
    }
  }
`;
export const updateReviewableItem = /* GraphQL */ `
  mutation UpdateReviewableItem(
    $input: UpdateReviewableItemInput!
    $condition: ModelReviewableItemConditionInput
  ) {
    updateReviewableItem(input: $input, condition: $condition) {
      id
      createdAt
      name
      description
      aggregateReview
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviews {
        nextToken
      }
    }
  }
`;
export const deleteReviewableItem = /* GraphQL */ `
  mutation DeleteReviewableItem(
    $input: DeleteReviewableItemInput!
    $condition: ModelReviewableItemConditionInput
  ) {
    deleteReviewableItem(input: $input, condition: $condition) {
      id
      createdAt
      name
      description
      aggregateReview
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviews {
        nextToken
      }
    }
  }
`;
export const createItemReview = /* GraphQL */ `
  mutation CreateItemReview(
    $input: CreateItemReviewInput!
    $condition: ModelItemReviewConditionInput
  ) {
    createItemReview(input: $input, condition: $condition) {
      id
      type
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      uName
      riName
      vName
      vCity
      vTerritory
      vLatitude
      vLongitude
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateItemReview = /* GraphQL */ `
  mutation UpdateItemReview(
    $input: UpdateItemReviewInput!
    $condition: ModelItemReviewConditionInput
  ) {
    updateItemReview(input: $input, condition: $condition) {
      id
      type
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      uName
      riName
      vName
      vCity
      vTerritory
      vLatitude
      vLongitude
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteItemReview = /* GraphQL */ `
  mutation DeleteItemReview(
    $input: DeleteItemReviewInput!
    $condition: ModelItemReviewConditionInput
  ) {
    deleteItemReview(input: $input, condition: $condition) {
      id
      type
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      uName
      riName
      vName
      vCity
      vTerritory
      vLatitude
      vLongitude
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const createItemReviewDraft = /* GraphQL */ `
  mutation CreateItemReviewDraft(
    $input: CreateItemReviewDraftInput!
    $condition: ModelItemReviewDraftConditionInput
  ) {
    createItemReviewDraft(input: $input, condition: $condition) {
      id
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      ttl
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateItemReviewDraft = /* GraphQL */ `
  mutation UpdateItemReviewDraft(
    $input: UpdateItemReviewDraftInput!
    $condition: ModelItemReviewDraftConditionInput
  ) {
    updateItemReviewDraft(input: $input, condition: $condition) {
      id
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      ttl
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteItemReviewDraft = /* GraphQL */ `
  mutation DeleteItemReviewDraft(
    $input: DeleteItemReviewDraftInput!
    $condition: ModelItemReviewDraftConditionInput
  ) {
    deleteItemReviewDraft(input: $input, condition: $condition) {
      id
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      ttl
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      name
      photo {
        key
        width
        height
      }
      joinDate
      createdAt
      updatedAt
      favVendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      favItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      itemReviews {
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      name
      photo {
        key
        width
        height
      }
      joinDate
      createdAt
      updatedAt
      favVendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      favItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      itemReviews {
        nextToken
      }
    }
  }
`;
export const generateUploadSig = /* GraphQL */ `
  mutation GenerateUploadSig($contentType: String!) {
    generateUploadSig(contentType: $contentType)
  }
`;
