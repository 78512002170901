
import React, { Fragment } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { Button, Spinner, Modal, Form, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

class UserCreator extends React.Component {
    constructor() {
        super();
        
        this.state = { 
            userName: "", 
        };
    }

    async createUser(event) {
        
        event.preventDefault();
        var createUserInput = {
            name: this.state.userName
        }

        const createUserResult = await API.graphql(graphqlOperation(mutations.createUser, { input: createUserInput }));
        console.info(`Finished await of createUserResult`);
        console.info(`Created User ${createUserResult.data.createUser}`);   
        
        this.setState({userId: createUserResult.data.createUser.id});
    }

    async updateUserName(event) {
           
        event.preventDefault();
        this.setState({ 
            userName: event.target.value });
    }

    render() {
        console.log("Rendering UserCreator");
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "100px", marginBottom: "100px" }}>
                   <h1>Create User</h1>
                    <Form 
                        noValidate
                        onSubmit={e => this.createUser(e)}>
                    <Form.Group>
                            <Form.Control style={{ width: "353px", float: "left" }} type="text" placeholder="User Name" onChange={e => this.updateUserName(e)}/>
                            <Button variant="primary" type="submit" style={{ float: "right"}}>
                                Create
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

class UserEditor extends React.Component {
    constructor(props) {
        super();
        
        this.state = { 
            newUserDisplayName: props.currentUserName, 
            showUserEditor: false
        };
    }

    async editUser(event) {
        
        event.preventDefault();
        if(this.props.currentUserName !== this.state.newUserDisplayName) {
            var updateUserInput = {
                id: this.props.userId,
                name: this.state.newUserDisplayName,
            }

            const updateUserResult = await API.graphql({
                query: mutations.updateUser,
                variables: {input: updateUserInput},
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            console.info(`Finished await of updateUserResult`);
            console.info(`Updated User ${updateUserResult.data.updateUser}`);
        }
        this.setState({showUserEditor: false});
        this.props.onEditCompleted(this.state.newUserDisplayName);
    }

    async updateUserName(event) {
           
        event.preventDefault();
        this.setState({ 
            newUserDisplayName: event.target.value });
    }

    async onEditNameButtonClicked(event) {
        event.preventDefault();
        
        this.setState({showUserEditor: true});
    }
    handleEditUserModalClosed() {
        this.setState({showUserEditor: false});
    }

    render() {
        console.log("Rendering UserEditor");
        return (
            <div style={{display: "inline-block"}}>
                <Button style={{display: "inline-block", verticalAlign: "text-bottom", marginLeft: "6px"}} onClick={this.onEditNameButtonClicked.bind(this)}>Edit Name</Button>
                <Modal show={this.state.showUserEditor} onHide={this.handleEditUserModalClosed.bind(this)}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                    <div style={{ padding: "24px 12px 24px 12px", marginBottom: "100px" }}>
                    <h1>Edit User</h1>
                        <Form 
                            noValidate
                            onSubmit={e => this.editUser(e)}>
                        <Form.Group>
                                <Form.Control style={{ width: "353px" }} type="text" value={this.state.newUserDisplayName} onChange={e => this.updateUserName(e)}/>
                                <br></br>
                                <Button variant="primary" type="submit" style={{ float: "right", marginLeft: "6px"}}>
                                    Save
                                </Button>
                                <Button variant="secondary" style={{ float: "right"}} onClick={this.handleEditUserModalClosed.bind(this)}>
                                    Cancel
                                </Button>
                            </Form.Group>
                        </Form>
                    </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
export { UserCreator, UserEditor };