/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:d7d4212f-7f34-431f-a1ad-68485b8f5290",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_WlYZFnP4V",
    "aws_user_pools_web_client_id": "7bqouti2l5pvuucrm418baoll4",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://he6shw43y5bivefdi6ytgyhphq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM"
};


export default awsmobile;
