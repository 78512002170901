import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Modal, ListGroup, Image, Button } from 'react-bootstrap';
import { getCloudinaryUrlFromKey } from './Helpers'

function useKeypress(key, action, capture1, capture2) {
    useEffect(() => {
        function onKeyup(e) {
          if (e.key === key) action()
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
      }, [capture1, capture2]);
}

function PhotoAlbumLightbox(props) {
    const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(props.photoIndex);
    
    const updateStateIfPropsChange = useEffect(
        () => {
            setLightboxPhotoIndex(props.photoIndex);
        },
        [props.photos, props.photoIndex, props.modalShowing], // Tells React to memoize regardless of arguments.
    );
    
    const nextImageClick = useCallback(
        () => {
            var newIndex = lightboxPhotoIndex+1;
            if(newIndex >= props.photos.length) {
                newIndex = 0;
            }
            setLightboxPhotoIndex(newIndex);
        },
        [props.photos, lightboxPhotoIndex], // Tells React to memoize regardless of arguments.
    );
    const previousImageClick = useCallback(
        () => {
            var newIndex = lightboxPhotoIndex-1;
            if(newIndex < 0) {
                newIndex = props.photos.length - 1;
            }
            setLightboxPhotoIndex(newIndex);
        },
        [props.photos, lightboxPhotoIndex], // Tells React to memoize regardless of arguments.
    );
    useEffect(() => {
        if(!props.photos) {
            return;
        }
        var photoLength = props.photos.length;
        function onKeyup(e) {
          if (e.key === 'ArrowRight') {
                var newIndex = lightboxPhotoIndex+1;
                if(newIndex >= photoLength) {
                    newIndex = 0;
                }
                setLightboxPhotoIndex(newIndex);
          }
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
      }, [props.photos, lightboxPhotoIndex]);

    useEffect(() => {
        if(!props.photos) {
            return;
        }
        var photoLength = props.photos.length;
        function onKeyup(e) {
          if (e.key === 'ArrowLeft') {
            var newIndex = lightboxPhotoIndex-1;
            if(newIndex < 0) {
                newIndex = photoLength - 1;
            }
            setLightboxPhotoIndex(newIndex);
          }
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
      }, [props.photos, lightboxPhotoIndex]);

    /*useKeypress('ArrowRight', () => {
        var newIndex = lightboxPhotoIndex+1;
        if(newIndex >= props.photos.length) {
            newIndex = 0;
        }
        setLightboxPhotoIndex(newIndex);
    }, props.photos, lightboxPhotoIndex);
    useKeypress('ArrowLeft', () => {
        var newIndex = lightboxPhotoIndex-1;
        if(newIndex < 0) {
            newIndex = props.photos.length - 1;
        }
        setLightboxPhotoIndex(newIndex);
    }, props.photos, lightboxPhotoIndex);*/

    if(!props.photos || props.photos.length === 0 || !props.modalShowing) {
        return (<Fragment />);
    }

    var previousString = "<";
    var nextString = ">";
    var arrows = (<Fragment></Fragment>);
    if(props.photos.length > 1) {
        arrows = (
            <div style={{margin: "auto", display: "flex", alignItems: "center", height: "100vh"}}>
                
                <div style={{display: "flex", flexDirection: "column", flexGrow: "0", height: "100vh"}}>
                    <div onClick={props.hideModalHandler} style={{flexGrow: "1"}}></div>
                    <div>
                        <Button onClick={previousImageClick} variant="light" style={{verticalAlign: "center", flexGrow: "0", fontSize: "300px", color: "lightgray"}}>
                            <div style={{marginBottom: "80px"}}>{previousString}</div>
                        </Button>
                    </div>
                    <div onClick={props.hideModalHandler} style={{flexGrow: "1"}}></div>
                </div>
                <div onClick={props.hideModalHandler} style={{flexGrow: "1", height: "100vh"}}></div>
                <div style={{display: "flex", flexDirection: "column", flexGrow: "0", height: "100vh"}}>
                    <div onClick={props.hideModalHandler} style={{flexGrow: "1"}}></div>
                    <div>
                        <Button onClick={nextImageClick} variant="light" style={{verticalAlign: "center", flexGrow: "0", fontSize: "300px", color: "lightgray"}}>
                            <div style={{marginBottom: "80px"}}>{nextString}</div>
                        </Button>
                    </div>
                    <div onClick={props.hideModalHandler} style={{flexGrow: "1"}}></div>
                </div>
            </div>
        );
    }
    var photoKey = props.photos[lightboxPhotoIndex].key;
    return (
        <Modal show={props.modalShowing} className="modalUnpadded"
                    dialogClassName="modalFullWidthDialog"
                    onHide={props.hideModalHandler}>
            <Modal.Body className="modalFullHeightContent">
                <div style={{display: "flex", flexDirection: "column", height: "100vh", width: "100vw", alignItems: "center", padding: "12px", paddingRight: "14px" }}>
                    {props.children}
                    <div style={{flexGrow: 1, backgroundImage: `url(${getCloudinaryUrlFromKey(photoKey, 1080, 1080, false, false)})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", height: "100%", width: "100%" }} >
                        {arrows}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function PhotoWithAlbumClickHandler(props) {
    var albumTease = (<Fragment></Fragment>);
    if(props.albumTeaseLength && props.albumTeaseLength > 1) {
        //photoNumber = (<div>1 of {props.photos.length}</div>);
        albumTease = (<Button size="sm" style={{alignSelf: "flex-end"}} data-photoindex={props.photoAlbumIndex}>+{props.albumTeaseLength-1} 📷</Button>);
    }
    return(
        <div style={{display: "flex", flexGrow: 1, height: props.height+"px", backgroundImage: `url(${getCloudinaryUrlFromKey(props.photo.key, props.width, props.height, false, false)})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "100%" }} 
            onClick={props.onClick} data-photoindex={props.photoAlbumIndex}>
                <div style={{flexGrow: "1"}} data-photoindex={props.photoAlbumIndex}></div>
                {albumTease}
        </div>
    );      
}
function PhotoAlbumWithLightbox(props) {
    const [modalShowing, setModalShowing] = useState(false);
    const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState(0);
    
    const showModal = useCallback(
        (e) => {
            var photoClickIndexStr = e.target.dataset.photoindex;
            var photoClickIndex = parseInt(photoClickIndexStr);
            setModalShowing(true);
            setLightboxPhotoIndex(photoClickIndex);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    const hideModal = useCallback(
        () => {
            setModalShowing(false);
        },
        [], // Tells React to memoize regardless of arguments.
    );
    
    if(!props.photos || props.photos.length === 0) {
        return (<Fragment></Fragment>);
    }

    var photoMap = props.photos.map((photo, index) => {
        if(index === 0 || props.showFullAlbum) {
            return( 
                <ListGroup.Item key={photo.key} style={{display: "block", border: "none", padding: "0px", width: "300px"}}>
                    <PhotoWithAlbumClickHandler onClick={showModal} photoAlbumIndex={index} photo={photo} width={300} height={300} albumTeaseLength={props.photos.length}/>
                </ListGroup.Item>);
        }
    });
    return (
        <div>
            <ListGroup>
                {photoMap}
            </ListGroup>
            <PhotoAlbumLightbox photos={props.photos} modalShowing={modalShowing} photoIndex={lightboxPhotoIndex} hideModalHandler={hideModal}>
                {props.children}
            </PhotoAlbumLightbox>
        </div>
    );
}

function PhotoCaption(props) {
    return (
        <div style={{ marginLeft: "6px", fontSize: "20px"}}>
            {props.text}
        </div>
    );
}
function PhotoCredit(props) {
    return (
        <div style={{ marginLeft: "6px", fontSize: "20px", fontWeight: "300", fontStyle: "italic"}}>
            {props.text}
        </div>
    );
}
export { PhotoAlbumWithLightbox, PhotoAlbumLightbox, PhotoCaption, PhotoCredit, PhotoWithAlbumClickHandler };