import React, { Fragment, Component, useState, useCallback, useEffect } from 'react';
import { Button, Navbar, Nav, Modal } from 'react-bootstrap';
import { Auth } from '@aws-amplify/auth'
import { Authenticator } from 'aws-amplify-react';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut, AmplifyConfirmSignIn, AmplifyConfirmSignUp, AmplifyGreetings, AmplifyForgotPassword, AmplifyRequireNewPassword, AmplifyTotpSetup, AmplifyVerifyContact } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { NavLink } from 'react-router-dom';
import {ReactComponent as ChocolateDonut} from '../ChocolateDonut.svg';

const appName = 'the Doughnut Review';
function StylizedAppName() {
    return (
      <div>
        
        <ChocolateDonut width="24px"></ChocolateDonut>
        {/* <div style={{borderRadius: "50%", background: "#AEABF2", width: "1.5em", height: "1.5em", display: "inline-block"}}>
          <span className="headerText" style={{color: "#1A040E", fontStyle: "italic", padding: "0px .5ch 0px .2ch"}}>the</span>
        </div> */}
        <span className="logoText" style={{color: "#1A040E", marginLeft: ".25ch"}}>|</span>
        <span className="logoText" style={{fontWeight: "300", color: "#1A040E", marginLeft: ".25ch", verticalAlign: "bottom"}}>the</span>
        <span className="logoText" style={{color: "#1A040E", marginLeft: ".25ch", verticalAlign: "bottom"}}>Doughnut Review</span>
      </div>
    );
}

function StylizedAppNameLink(props) {
  if(props.showHomeLink === false) {
    return (<StylizedAppName />);
  }
  return (
    <NavLink to={`/`}><StylizedAppName /></NavLink>
  );
}

function NavigationBarSwitcher(props) {
  if(props.authState !== 'signedIn') {
      return (
          <SignedOutNavigationBar showHomeLink={props.showHomeLink}></SignedOutNavigationBar>
      );
  }
  return (<NavigationBar showHomeLink={props.showHomeLink}></NavigationBar>);
}

class NavigationBar extends React.Component {
    render() {
      console.log("Rendering NavigationBar");
        return (
                <Navbar collapseOnSelect expand="lg" bg="nav" variant="light" fixed="top">
                <Navbar.Brand>
                      <StylizedAppNameLink showHomeLink={this.props.showHomeLink}/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav>
                    <Navbar.Text style={{paddingLeft: ".5rem", paddingRight: ".5rem"}}>
                      <NavLink to={`/me`}>Profile</NavLink>
                    </Navbar.Text>
                    <div style={{ display: "inline-block" }}>
                      <Authenticator hideDefault="true">
                        <SignOutButton override={'SignOut'} fontSize="1rem" skipPadding={true}/> 
                      </Authenticator>
                    </div>
                    </Nav>
                </Navbar.Collapse>
                </Navbar>
        );
    }
}

function SignedOutNavigationBar(props) {

  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [authState, setAuthState] = React.useState();
  
  const onSignInClicked = useCallback(
      () => {
          setShowSignIn(true);
      },
      [], // Tells React to memoize regardless of arguments.
  );
  const onSignUpClicked = useCallback(
      () => {
          setShowSignUp(true);
      },
      [], // Tells React to memoize regardless of arguments.
  );
  const handleAuthenticatorModalClosed = useCallback(
      () => {
          setShowSignIn(false);
          setShowSignUp(false);
          //window.history.pushState('index', 'New Tab', 'index.html');
          //window.location.reload(false); 
      },
      [], // Tells React to memoize regardless of arguments.
  );
  useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          if(nextAuthState === AuthState.SignedIn) {
              setShowSignIn(false);
              setShowSignUp(false);
              //window.history.pushState('index', 'New Tab', 'index.html');
              window.location.reload(false); 
          }
      });
  }, []);
  
  return (
          <Fragment>
            <Navbar collapseOnSelect expand="lg" bg="nav" variant="light" fixed="top">
            <Navbar.Brand>
              <StylizedAppNameLink showHomeLink={props.showHomeLink}/>
            </Navbar.Brand>
            <Nav className="mr-auto">
            </Nav>
            <Nav>
            <div style={{ display: "inline-block" }}>
                <SignInButton override={'SignIn'} onSignInClicked={onSignInClicked}/> 
                <div style={{display: "inline-block", width: "12px"}}></div>
                <SignUpButton override={'SignUp'} onSignUpClicked={onSignUpClicked}/> 
            </div>
            </Nav>
            </Navbar>
            <Modal show={showSignIn || showSignUp} onHide={handleAuthenticatorModalClosed}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <AmplifyAuthenticator initialAuthState={(showSignIn) ? "signin" : "signup"}>
                  <AmplifySignUp headerText="Join the DOUGHNUT REVIEW" slot="sign-up"></AmplifySignUp>
              </AmplifyAuthenticator>
            </Modal.Body>
          </Modal>
        </Fragment>
  );
}

class SignOutButton extends Component {
    constructor() {
      super();
      this.gotoSignOut = this.gotoSignOut.bind(this);
    }
  
    gotoSignOut() {
        
        Auth.signOut();
      //clearUserStorageAndSignOut();
    }
  
    render() {
      console.log("Rendering SignOutButton");
      var fontSize = "12px";
      if(this.props.fontSize) {
          fontSize = this.props.fontSize;
      }
      var padding = <Button onClick={this.gotoSignOut}  className="headerTextLight" variant="outline-dark" style={{fontSize: fontSize, padding: "0px 6px 0px 6px", verticalAlign: "text-bottom"}}>Sign Out</Button>;
      if(this.props.isOutline) {
          padding = <Button onClick={this.gotoSignOut}  className="headerTextLight" variant="outline-light" style={{fontSize: fontSize, padding: "0px 6px 0px 6px", verticalAlign: "text-bottom"}}>Sign Out</Button>;
      }
      else if(this.props.skipPadding) {
          padding = <Button onClick={this.gotoSignOut}  className="headerTextLight" variant="outline-dark" style={{fontSize: fontSize, verticalAlign: "text-bottom"}}>Sign Out</Button>;
      }
      return (
        <div>
          {/* only render this component when the authState is 'signedIn' */}
          { this.props.authState === 'signedIn' && 
          <div id="blerg" style={{display: "inline-block"}}>
              {padding}
          </div>
          }
        </div>
      );
    }
  }
  

class SignInButton extends Component {
  render() {
    console.log("Rendering SignInButton");
    return (
      <Fragment>
          {/* only render this component when the authState is 'signedOut' */}
          { this.props.authState !== 'signedIn' && 
            <Button className="headerTextLight" onClick={this.props.onSignInClicked} variant="outline-dark" style={{verticalAlign: "text-bottom"}}>Sign In</Button>
          }
      </Fragment>
    );
  }
}


class SignUpButton extends Component {
  render() {
    console.log("Rendering SignUpButton");
    return (
      <Fragment>
          {/* only render this component when the authState is 'signedOut' */}
          { this.props.authState !== 'signedIn' && 
            <Button onClick={this.props.onSignUpClicked} className="headerTextLight" variant="outline-dark" style={{verticalAlign: "text-bottom"}}>Sign Up</Button>
          }
      </Fragment>
    );
  }
}

  

export { NavigationBarSwitcher, SignOutButton, SignedOutNavigationBar, StylizedAppName, StylizedAppNameLink };
