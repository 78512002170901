import React, { Fragment } from 'react';
import { Component } from 'react';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { ReviewableItemLoader } from './ReviewableItem';
import { ItemReviewLoader } from './ItemReview';
import { UserLoader, UserGroupLoader, MeLoader } from './User';
import { VendorLoader } from './Vendor';
import { VendorCreator } from './VendorCreator';
import { ReviewableItemCreator } from './ReviewableItemCreator';
import { UserCreator } from './UserCreator';
import { ReviewCreator } from './ReviewCreator';
import { HomePageLoader } from './HomePage';
import { AboutFeature } from './SignedOutDynamicHome';
import { ShopsLoader } from './ShopsFinder';
import { NavigationBarSwitcher } from './NavigationBar';
import { Amplify } from '@aws-amplify/core'
import awsconfig from '../aws-exports'

console.log("Using browser history");
var history = createBrowserHistory();

class UrlRouter extends Component {
    render() {  
        console.log("Rendering UrlRouter");
        if(this.props.authState === 'loading') {
            return (<Fragment></Fragment>);
        }
        
        if(this.props.authState === 'signedIn') {  
            awsconfig.aws_appsync_authenticationType = "AMAZON_COGNITO_USER_POOLS";
            Amplify.configure(awsconfig);
        }
        else {
            awsconfig.aws_appsync_authenticationType = "AWS_IAM";
            Amplify.configure(awsconfig);
        }
        console.log("window path " + window.location.href);
        return (
            <Fragment>
            <Router history={history}>
                {/* <Container style={{width: "100%", padding: "0px"}}> */}
                    <Row style={{width: "100%", margin: "0px", backgroundColor: "rgba(255, 200, 200, .75)"}}>
                        <Col className="center-div"  style={{padding: "0px"}}>

                            
                            <Route
                                path="/" exact
                                render={props => <NavigationBarSwitcher showHomeLink={false} authState={this.props.authState} />}
                            />
                            <div style={{display: "flex", flexWrap: "wrap"}}>
                            <div style={{flexGrow: "5"}}>

                            <div style={{display: "flex"}}>
                                <div style={{flexGrow: "1", minWidth: "0px"}}></div>
                                <div className="opaqueBackground" style={{flexGrow: "2", maxWidth: "48px"}}></div>
                            <div class="opaqueBackground" style={{maxWidth: "1202px", flexGrow: "2"}}>

                                <Route path="/" exact component={HomePageLoader} />
                                <Route
                                    path="/addVendor" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/addVendor"
                                    render={props => <VendorCreator />}
                                />
                                <Route
                                    path="/addDoughnut/:vendorId" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/addDoughnut/:vendorId"
                                    render={props => <ReviewableItemCreator vendorId={props.match.params.vendorId}/>}
                                />
                                <Route
                                    path="/addReview/:itemId" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/addReview/:itemId"
                                    render={props => <ReviewCreator itemId={props.match.params.itemId}/>}
                                />
                                <Route
                                    path="/addUser" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/addUser"
                                    render={props => <UserCreator />}
                                />
                                <Route
                                    path="/r/:reviewId" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/r/:reviewId"
                                    render={props => <ItemReviewLoader id={props.match.params.reviewId} />}
                                />
                                <Route
                                    path="/d/:itemId" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/d/:itemId"
                                    render={props => <ReviewableItemLoader id={props.match.params.itemId} />}
                                />
                                <Route
                                    path="/v/:vendorId" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/v/:vendorId"
                                    render={props => <VendorLoader id={props.match.params.vendorId} />}
                                />
                                <Route
                                    path="/u/:userId" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/u/:userId" exact
                                    render={props => <UserLoader id={props.match.params.userId} />}
                                />
                                <Route
                                    path="/u/:userId/:userId2" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/u/:userId/:userId2"
                                    render={props => <UserGroupLoader id={props.match.params.userId} id2={props.match.params.userId2} />}
                                />
                                <Route
                                    path="/me" exact
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/me"
                                    render={props => <MeLoader />}
                                />
                                <Route
                                    path="/shops"
                                    render={props => <NavigationBarSwitcher authState={this.props.authState} />}
                                />
                                <Route
                                    path="/shops" exact
                                    render={props => <ShopsLoader location={props.location}/>}
                                />
                                <Route
                                    path="/shops/:token"
                                    render={props => <ShopsLoader token={props.match.params.token} location={props.location}/>}
                                />
                            </div>
                                <div className="opaqueBackground" style={{flexGrow: "2", maxWidth: "48px"}}></div>
                                <div style={{flexGrow: "1", minWidth: "0px", minHeight: "100vh", backgroundColor: "rgba(255, 255, 255, 0)"}}></div>
                            </div>
                            </div>
                            <div style={{display: "flex", flexGrow: "1"}}>
                                <div style={{flexGrow: "1", minWidth: "48px", backgroundColor: "rgba(255, 255, 255, 0)"}}></div>
                            <div>
                                <Route path="/" exact component={AboutFeature} />
                            </div>
                                <div style={{flexGrow: "1", minWidth: "48px"}}></div>
                            </div>
                            </div>
                        </Col>
                    </Row>
                {/* </Container> */}
            </Router>
            </Fragment>
        );
    }
}

export { UrlRouter };