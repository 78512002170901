import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';

import { Connect } from 'aws-amplify-react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Authenticator } from 'aws-amplify-react';
import * as queries from '../graphql/queries';
import { ListGroup, Button, Spinner, Modal, Form, Dropdown, DropdownButton, ButtonGroup, Image } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { Spacer, GetUserIdByOwner } from './Helpers'
import { getCloudinaryUrlFromKey, GeoApifyMap } from './Helpers'
import { VendorLocation, VendorLink } from './ItemReview'

const getVendorsByLocation = /* GraphQL */ `
  query GetVendorsByLocation(
    $territory: String
    $city: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVendorsByLocation(
      territory: $territory
      city: $city
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
        items {
            id
            name
            description
            latitude
            longitude
            city
            territory
        }
        nextToken
    }
  }
`;


function VendorList(props) {
    console.log("rendering Vendor list");
    if(!props.vendors) {
        return (<Fragment></Fragment>);
    }
    const listItems = props.vendors.map((vendor) =>
        <ListGroup.Item key={vendor.id} style={{border: "none"}}>
                <div style={{ padding: "24px 12px 24px 12px" }}>
                    <h1><VendorLink vendor={vendor} /></h1>
                    <div className="headerTextLight">{vendor.description}</div>
                    <VendorLocation vendor={vendor}/>
                </div>
        </ListGroup.Item>
        );
    return (
        <div>
            <ListGroup>
                {listItems}
            </ListGroup>
        </div>);
}

function CheckBox(props) {
    return (<Form.Check checked={props.territoryName===props.selectedName} type="radio" name="formRadios" id={props.territoryName} label={props.label} onChange={props.handleToggle} data-territoryname={props.territoryName}/>);
}

function VendorFilter(props) {
    const [selectedTerritory, setSelectedTerritory] = useState("");
    console.log("rendering Vendor filter");
    
    const handleToggle = useCallback((e) => {
        props.handleFilterChange(e.target.dataset.territoryname);
        setSelectedTerritory(e.target.dataset.territoryname);
    },
    [props.handleFilterChange], // Tells React to memoize regardless of arguments.
    );
    const handleClearFilter = useCallback((e) => {
        props.handleFilterChange("");
        setSelectedTerritory("");
    },
    [], // Tells React to memoize regardless of arguments.
    );
    return (
        
        <div class="lightOpaqueBackground" style={{padding: "24px", marginBottom: "24px"}}>      
        <div class="bg-nav headerTextLight" style={{borderBottom: "1px solid #456"}}>Filter</div> 
        <Form>
        <Form.Group controlId="formBasicCheckbox">
            <CheckBox label={"All"} handleToggle={handleToggle} selectedName={selectedTerritory} territoryName={""}/>
            <CheckBox label={"California"} handleToggle={handleToggle} selectedName={selectedTerritory}  territoryName={"California"}/>
            <CheckBox label={"Oregon"} handleToggle={handleToggle} selectedName={selectedTerritory}  territoryName={"Oregon"}/>
            <CheckBox label={"Washington"} handleToggle={handleToggle} selectedName={selectedTerritory}  territoryName={"Washington"}/>
        </Form.Group>
        <Button onClick={handleClearFilter}>Clear Filter</Button>
        </Form>
        </div>);
}

function ShopsLoader(props) {
    const [vendors, setVendors] = useState(null);
    const [nextToken, setNextToken] = useState(null);
    const [territoryFilter, setTerritoryFilter] = useState(null);

    useEffect(() => {
        async function getVendors(territoryFilter) {
            if(territoryFilter) {
                const vendors = await API.graphql({
                    query: getVendorsByLocation,
                    variables: {territory: territoryFilter, limit: 10, sortDirection: "DESC"}
                  });
                  if(vendors.data && vendors.data.getVendorsByLocation && vendors.data.getVendorsByLocation.items) {
                    setVendors(vendors.data.getVendorsByLocation.items);
                    setNextToken(vendors.data.getVendorsByLocation.nextToken);
                  }
            }
            else {
                var variables = {
                    limit: "20"
                }
                if(props.token) {
                    variables.nextToken = props.token;
                }
                const vendorsList = await API.graphql({
                    query: queries.listVendors,
                    variables: variables
                    });
                    setVendors(vendorsList.data.listVendors.items);
                    setNextToken(vendorsList.data.listVendors.nextToken);
            }
        }
        getVendors(territoryFilter);
    }, [props.token, territoryFilter]);

    const handleFilterChange = useCallback((territoryName) => {
        setTerritoryFilter(territoryName);
    },
    [], // Tells React to memoize regardless of arguments.
    );

    var nextLink =(<Fragment />);
    var prevPath = (props.location) ? props.location.pathname : "";
    if(nextToken) {
        nextLink = (<NavLink to={{pathname: `/shops/${nextToken}`, state: { prevPath: prevPath }}} >More</NavLink>);
    }
    let history = useHistory();
    var backLink =(<Fragment />);
    if(props.token && props.location && vendors) {
        backLink = (<NavLink to={props.location.state.prevPath}>Back</NavLink>);
        //backLink = (<NavLink to={`/shops`} onClick={() => history.goBack()}>Back</NavLink>);
    }
    return (
        <div style={{marginTop: "100px", marginLeft: "48px", marginRight: "48px", display: "inline-block"}}>
            <div style={{display: "inline-block", marginRight: "24px"}}>
            <VendorFilter handleFilterChange={handleFilterChange}></VendorFilter>
            </div>
            <div style={{display: "inline-block", verticalAlign: "top"}}>
            <VendorList vendors={vendors}></VendorList>
            </div>
            <div style={{display: "flex", fontSize: "20px"}}>
                <div style={{flexGrow: "1"}}>
                    {backLink}
                </div>
                <div style={{flexGrow: "0"}}>
                    {nextLink}
                </div>
            </div>
        </div>
    );
}



class VendorSearch extends React.Component {
    constructor() {
        super();

        this.state = { 
            storeName: "", 
            city: "", 
            territory: "", 
            country: "", 
        };
    }

    async searchForVendor(event) {
        
        //TODO: Search
        /*event.preventDefault();
        var createReviewableItemInput = {
            name: this.state.reviewableItemName,
            description: this.state.reviewableItemDescription,
        }

        const createReviewableItemResult = await API.graphql(graphqlOperation(queries., { input: createReviewableItemInput }));
        console.info(`Finished await of createReviewableItemResult`);
        console.info(`Created ReviewableItem ${createReviewableItemResult.data.createReviewableItem}`);   
        
        this.setState({reviewableItemId: createReviewableItemResult.data.createReviewableItem.id});*/
    }

    async updateStoreName(event) {
           
        event.preventDefault();
        this.setState({ 
            storeName: event.target.value });
    }

    render() {
        console.log("Rendering VendorSearch");
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "100px", marginBottom: "100px" }}>
                   <h1>Find a Doughnut Provider</h1>
                   <Form 
                        noValidate
                        onSubmit={e => this.searchForVendor(e)}>
                        <Form.Group>
                            <Form.Control style={{ width: "353px", float: "left" }} type="text" placeholder="Store Name" onChange={e => this.updateStoreName(e)}/>
                            <Button variant="primary" type="submit" style={{ float: "right"}}>
                                Find
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Fragment>
        );
    }
}


export { ShopsLoader };
