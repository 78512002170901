

import React, { Fragment } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { Button, Spinner, Modal, Form, Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';

class ReviewableItemCreator extends React.Component {
    constructor() {
        super();
        
        this.state = { 
            name: "", 
            description: "",
        };
    }

    async createReviewableItem(event) {
        
        event.preventDefault();
        var createReviewableItemInput = {
            name: this.state.name,
            description: this.state.description,
            reviewableItemVendorId: this.props.vendorId
        };
        
        const createReviewableItemResult = await API.graphql({
            query: mutations.createReviewableItem,
            variables: {input: createReviewableItemInput},
            authMode: 'AMAZON_COGNITO_USER_POOLS'
          });
        console.info(`Finished await of createReviewableItemResult`);
        console.info(`Created ReviewableItem ${createReviewableItemResult.data.createReviewableItem}`);   
        
        this.setState({reviewableItemId: createReviewableItemResult.data.createReviewableItem.id});
    }

    async updateReviewableItemName(event) {
           
        event.preventDefault();
        this.setState({ 
            name: event.target.value });
    }
    async updateReviewableItemDescription(event) {
           
        event.preventDefault();
        this.setState({ 
            description: event.target.value });
    }
    async updateStateValue(event, stateKey) {
           
        event.preventDefault();
        this.setState({
            [stateKey]: event.target.value });
    }

    render() {
        console.log("Rendering ReviewableItemCreator");
        return (
            <Fragment>
                <div style={{ padding: "24px 12px 24px 12px", marginTop: "100px", marginBottom: "100px" }}>
                   <h1>Create Doughnut</h1>
                    <Form 
                        noValidate
                        onSubmit={e => this.createReviewableItem(e)}>
                        <Form.Group>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="Doughnut Name" onChange={e => this.updateStateValue(e, "name")}/>
                            <Form.Control style={{ width: "353px", marginBottom: "12px" }} type="text" placeholder="Doughnut Description" onChange={e => this.updateStateValue(e, "description")}/>
                            <Button variant="primary" type="submit" style={{ float: "right"}}>
                                Create
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Fragment>
        );
    }
}

export { ReviewableItemCreator };