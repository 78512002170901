/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listMultiLocationVendors = /* GraphQL */ `
  query ListMultiLocationVendors(
    $filter: ModelMultiLocationVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultiLocationVendors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMultiLocationVendor = /* GraphQL */ `
  query GetMultiLocationVendor($id: ID!) {
    getMultiLocationVendor(id: $id) {
      id
      name
      description
      aggregateItemReview
      createdAt
      updatedAt
      locations {
        nextToken
      }
      items {
        nextToken
      }
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      id
      name
      description
      rating
      latitude
      longitude
      streetAddress
      city
      territory
      country
      websiteUrl
      photo {
        key
        width
        height
      }
      aggregateItemReview
      createdAt
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      items {
        nextToken
      }
      allReviews {
        nextToken
      }
    }
  }
`;
export const listReviewableItems = /* GraphQL */ `
  query ListReviewableItems(
    $filter: ModelReviewableItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviewableItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReviewableItem = /* GraphQL */ `
  query GetReviewableItem($id: ID!) {
    getReviewableItem(id: $id) {
      id
      createdAt
      name
      description
      aggregateReview
      updatedAt
      multiLocationVendor {
        id
        name
        description
        aggregateItemReview
        createdAt
        updatedAt
      }
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviews {
        nextToken
      }
    }
  }
`;
export const getItemReview = /* GraphQL */ `
  query GetItemReview($id: ID!) {
    getItemReview(id: $id) {
      id
      type
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      uName
      riName
      vName
      vCity
      vTerritory
      vLatitude
      vLongitude
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const listItemReviews = /* GraphQL */ `
  query ListItemReviews(
    $filter: ModelItemReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        owner
        createdAt
        rating
        reviewText
        uName
        riName
        vName
        vCity
        vTerritory
        vLatitude
        vLongitude
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItemReviewsByCreatedAt = /* GraphQL */ `
  query GetItemReviewsByCreatedAt(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelItemReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getItemReviewsByCreatedAt(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        owner
        createdAt
        rating
        reviewText
        uName
        riName
        vName
        vCity
        vTerritory
        vLatitude
        vLongitude
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItemReviewDraft = /* GraphQL */ `
  query GetItemReviewDraft($id: ID!) {
    getItemReviewDraft(id: $id) {
      id
      owner
      createdAt
      rating
      reviewText
      photos {
        key
        width
        height
      }
      ttl
      updatedAt
      vendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      reviewableItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      user {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
    }
  }
`;
export const listItemReviewDrafts = /* GraphQL */ `
  query ListItemReviewDrafts(
    $filter: ModelItemReviewDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemReviewDrafts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        rating
        reviewText
        ttl
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      name
      photo {
        key
        width
        height
      }
      joinDate
      createdAt
      updatedAt
      favVendor {
        id
        name
        description
        rating
        latitude
        longitude
        streetAddress
        city
        territory
        country
        websiteUrl
        aggregateItemReview
        createdAt
        updatedAt
      }
      favItem {
        id
        createdAt
        name
        description
        aggregateReview
        updatedAt
      }
      itemReviews {
        nextToken
      }
    }
  }
`;
export const userByOwner = /* GraphQL */ `
  query UserByOwner(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        joinDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
