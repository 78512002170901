import React, { Fragment } from 'react';

import { UrlRouter }from './components/UrlRouter';

import { Authenticator } from 'aws-amplify-react';
import { Amplify } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { PubSub } from '@aws-amplify/pubsub'
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from './aws-exports'
import { getCloudfrontUrlFromKey } from './components/Helpers'

Amplify.configure(awsconfig);
Auth.configure(awsconfig);
PubSub.configure(awsconfig);

const themes = {
    light: {
      outlineVariant: "outline-dark",
      onBodyButtonVariant: "dark"
    },
    dark: {
      outlineVariant: "outline-light",
      onBodyButtonVariant: "light"
    }
};
const isDarkMode = getComputedStyle(document.body).getPropertyValue('--isDarkMode');
export const ThemeContext = React.createContext((isDarkMode === "true") ? themes.dark : themes.light);

/*const App = () => (
    <Fragment>
    <ThemeContext.Provider value={(isDarkMode === "true") ? themes.dark : themes.light}>
            <AmplifyAuthenticator  hideDefault={true}>
                  <UrlRouter /> 
            </AmplifyAuthenticator>
            </ThemeContext.Provider>
    </Fragment>
);*/


const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();

  React.useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setUser(authData);
          
      });
  }, []);

return (
    <div className="App" style={{backgroundImage: `url(${getCloudfrontUrlFromKey("assets/DonutsColorful.png")})`, backgroundSize: "400px"}} >
      {/* <div style={{backgroundImage: `url(${getCloudfrontUrlFromKey("assets/DonutsColorful.png")})`, backgroundSize: "400px", position: "absolute", opacity: ".9", minWidth: "100vw", minHeight: "100vh"}}></div> */}
      <ThemeContext.Provider value={(isDarkMode === "true") ? themes.dark : themes.light}>
          <Authenticator  hideDefault={true}>
            <UrlRouter authState={authState}/>
          </Authenticator>
      </ThemeContext.Provider>
    </div>
  )
}



export default App;
